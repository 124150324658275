import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import style from "./slotMachine.module.css";
import orange from "../../sprites/spriteOrange/Mask group.png";
import pear from "../../sprites/spritePear/Mask group.png";
import plum from "../../sprites/spritePlum/Mask group.png";
import stars from "../../sprites/spriteStars/Mask group.png";
import wild from "../../sprites/spriteWild/Mask group.png";
import OrangeAnimation from "../../components/orangeAnimation/orangeAnimation";
import PlumAnimation from "../../components/plumAnimation/plumAnimation";
import PearAnimation from "../../components/pearAnimation/pearAnimation";
import StarsAnimation from "../../components/startsAnimation/startsAnimation";
import WildAnimation from "../../components/wildAnimation/wildAnimation";

import win from "../../songs/win.mp3";
import lose from "../../songs/lose.mp3";
import useSound from "../../utils/soundUtils";

const frames = [
  { image: pear, alt: "pear", id: 1, wild: "x1" },
  { image: plum, alt: "plum", id: 2, wild: "x4" },
  { image: stars, alt: "stars", id: 3, wild: "x6" },
  { image: orange, alt: "orange", id: 4, wild: "x10" },
  { image: wild, alt: "wild", id: 5, wild: "wild" },
];

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const SlotMachineGameTree = ({
  startSpin,
  setStartSpin,
  bet,
  setWinCounter,
  chosenWild,
  setFreezeButton,
}) => {
  const [slots, setSlots] = useState([[], [], []]);
  const [spinKeys, setSpinKeys] = useState([0, 1, 2]);
  const [activeSlot, setActiveSlot] = useState(-1);

  const [countResultLine, setCountResultLine] = useState([]);
  const [countResultDiagonal, setCountResultDiagonal] = useState([]);
  const [gameResult, setGameResult] = useState(null);

  const { playSound } = useSound();

  const [lines, setLines] = useState([]);

  console.log("countResultDiagonal", countResultDiagonal);

  console.log("slots", slots);

  useEffect(() => {
    resetGame();
  }, []);

  const resetGame = () => {
    const newSlots = [];
    for (let i = 0; i < 3; i++) {
      // newSlots.push(shuffleArray([...frames]).slice(0, 3));

      // Клонируем массив с помощью метода slice
      const clonedFrames = frames.slice();

      // Перемешиваем клонированный массив
      const shuffledFrames = shuffleArray(clonedFrames);

      // Обрезаем массив до 3 элементов
      const slicedFrames = shuffledFrames.slice(0, 3);

      // Добавляем в newSlots
      newSlots.push(slicedFrames);
    }
    setSlots(newSlots);
  };

  const checkWin = (result) => {
    const { slot1, slot2, slot3 } = result;
    let win = false;
    const newLines = [];

    let rows = [];
    let diagonal = [];

    let countLine = [];
    let countDiagonal = [];

    let resWin = [];

    // Функция для проверки совпадения с учетом wild
    const isMatch = (a, b, c) => {
      // Заменяем только значение wild, не трогая id
      const wildA = a.id === 5 && chosenWild ? chosenWild : a.wild;
      const wildB = b.id === 5 && chosenWild ? chosenWild : b.wild;
      const wildC = c.id === 5 && chosenWild ? chosenWild : c.wild;

      // Проверка по id или по wild значению
      return (
        (a.id === b.id && b.id === c.id) || (wildA === wildB && wildB === wildC)
      );
    };

    // Проверка рядов
    for (let i = 0; i < 3; i++) {
      if (isMatch(slot1[i], slot2[i], slot3[i])) {
        console.log("row");

        win = true;
        rows.push(slot1[i].id);
        newLines.push({ type: "row", index: i });

        let res = {
          slot1: {
            id: slot1[i].id,
            index: i,
          },
          slot2: {
            id: slot2[i].id,
            index: i,
          },
          slot3: {
            id: slot3[i].id,
            index: i,
          },
        };

        countLine.push(res);
      }
    }

    setCountResultLine(countLine);

    // Проверка диагоналей
    if (isMatch(slot1[0], slot2[1], slot3[2])) {
      console.log("diagonal0");

      win = true;
      diagonal.push(slot1[0].id);

      newLines.push({ type: "diagonal", index: 0 });

      let res = {
        slot1: {
          id: slot1[0].id,
          index: 0,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[2].id,
          index: 2,
        },
      };

      countDiagonal.push(res);
    }
    if (isMatch(slot1[2], slot2[1], slot3[0])) {
      console.log("diagonal1");

      win = true;
      diagonal.push(slot3[0].id);

      newLines.push({ type: "diagonal", index: 1 });

      let res = {
        slot1: {
          id: slot1[2].id,
          index: 2,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[0].id,
          index: 0,
        },
      };

      countDiagonal.push(res);
    }

    // if (rows) {
    //   resWin.push(...rows);
    // }
    // if (diagonal) {
    //   resWin.push(...diagonal);
    // }

    if (rows) {
      resWin = resWin.concat(rows);
    }
    if (diagonal) {
      resWin = resWin.concat(diagonal);
    }

    setGameResult(resWin);

    setCountResultDiagonal(countDiagonal);
    setLines(newLines);
    return win;
  };

  console.log("gameResult", gameResult);

  useEffect(() => {
    if (Array.isArray(gameResult)) {
      let res = [];

      gameResult.forEach((item) => {
        let winPercentage = 0;

        switch (item) {
          case 1:
            winPercentage = 0.5;
            break;
          case 2:
            winPercentage = 1.0;
            break;
          case 3:
            winPercentage = 2.0;
            break;
          case 4:
            winPercentage = 3.0;
            break;
          case 5:
            winPercentage = 5.0;
            break;
          default:
            winPercentage = 0;
            break;
        }

        const winAmount = bet * winPercentage;
        res.push(winAmount);
      });

      const sumNumbers = res.reduce((a, b) => a + b, 0);

      setWinCounter(sumNumbers);
    }
  }, [gameResult]);

  const handleSpin = () => {
    setCountResultLine([]);
    setCountResultDiagonal([]);

    const newSlots = [];
    for (let i = 0; i < 3; i++) {
      // newSlots.push(shuffleArray([...frames]).slice(0, 3));

      // Клонируем массив с помощью метода slice
      const clonedFrames = frames.slice();

      // Перемешиваем клонированный массив
      const shuffledFrames = shuffleArray(clonedFrames);

      // Обрезаем массив до 3 элементов
      const slicedFrames = shuffledFrames.slice(0, 3);

      // Добавляем в newSlots
      newSlots.push(slicedFrames);
    }
    setSpinKeys((prevKeys) => prevKeys.map((key) => key + 1));

    setTimeout(() => {
      setSlots(newSlots);
    }, 1000);

    const result = {
      slot1: newSlots[0],
      slot2: newSlots[1],
      slot3: newSlots[2],
    };

    setTimeout(() => {
      if (checkWin(result)) {
        playSound(win);
        setFreezeButton(false);
      } else {
        playSound(lose);
        setFreezeButton(false);
      }
      setStartSpin(false);
    }, 1500);

    setActiveSlot(0);
  };

  useEffect(() => {
    if (startSpin) {
      handleSpin();
    }
  }, [startSpin]);

  useEffect(() => {
    if (activeSlot >= 0 && activeSlot < slots.length) {
      const timer = setTimeout(() => {
        setActiveSlot((prev) => prev + 1);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [activeSlot]);

  const renderSlot = (slotIndex, slot) => {
    const animatedImages = Array.from(
      { length: 30 },
      (_, index) => slots[slotIndex][index % slots[slotIndex].length]
    );

    const getImageStyle = (slotIndex, indexInSlot) => {
      let showBack = false;

      // Проверяем все линии
      countResultLine.forEach((countLine) => {
        switch (slotIndex) {
          case 0:
            if (countLine.slot1 && countLine.slot1.index === indexInSlot) {
              showBack = true;
            }
            break;
          case 1:
            if (countLine.slot2 && countLine.slot2.index === indexInSlot) {
              showBack = true;
            }
            break;
          case 2:
            if (countLine.slot3 && countLine.slot3.index === indexInSlot) {
              showBack = true;
            }
            break;
          case 3:
            if (countLine.slot4 && countLine.slot4.index === indexInSlot) {
              showBack = true;
            }
            break;
          default:
            break;
        }
      });

      // Проверяем диагональные линии
      if (countResultDiagonal) {
        countResultDiagonal.length > 0 &&
          countResultDiagonal.forEach((countDiagonal) => {
            switch (slotIndex) {
              case 0:
                if (
                  countDiagonal.slot1 &&
                  countDiagonal.slot1.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              case 1:
                if (
                  countDiagonal.slot2 &&
                  countDiagonal.slot2.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              case 2:
                if (
                  countDiagonal.slot3 &&
                  countDiagonal.slot3.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              case 3:
                if (
                  countDiagonal.slot4 &&
                  countDiagonal.slot4.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              default:
                break;
            }
          });
      }

      return { showBack };
    };

    const vh = window.innerHeight / 100;
    const animationHeight = -113.4 * vh;

    return (
      <div className={style.slotBlock} key={slotIndex}>
        <motion.div
          key={spinKeys[slotIndex]}
          className={style.slotsInner}
          initial={{ y: animationHeight }}
          animate={{ y: activeSlot >= slotIndex ? 0 : animationHeight }}
          transition={{ duration: 1, ease: "linear" }}
        >
          {animatedImages.map((images, index) => {
            const { showBack } = getImageStyle(slotIndex, index % slot.length);
            return (
              <div className={style.slotsItem} key={index}>
                {showBack ? (
                  images?.alt === "orange" ? (
                    <OrangeAnimation />
                  ) : images && images.alt === "plum" ? (
                    <PlumAnimation />
                  ) : images && images.alt === "pear" ? (
                    <PearAnimation />
                  ) : images && images.alt === "stars" ? (
                    <StarsAnimation />
                  ) : images && images.alt === "wild" ? (
                    <WildAnimation />
                  ) : (
                    <img
                      src={images && images.image}
                      alt={images && images.alt}
                      className={style.slotsItemImage}
                    />
                  )
                ) : (
                  <img
                    src={images && images.image}
                    alt={images && images.alt}
                    className={style.slotsItemImage}
                  />
                )}
              </div>
            );
          })}
        </motion.div>
      </div>
    );
  };

  return (
    <div className={style.slotsMachine}>
      {slots.map((slot, index) => renderSlot(index, slot))}
    </div>
  );
};

export default SlotMachineGameTree;
