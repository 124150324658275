import React from "react";
import style from "./winLine.module.css";
import cancellSVG from "../../svg/cancell.svg";
import { NavLink } from "react-router-dom";

export default function WinLineGameOne() {
  return (
    <div className={style.gameOne}>
      <div className={style.gameBlockBlock}>
        <div className={style.winLineBlock}>
          <div className={style.winLine}>
            <div className={style.tableBlock}>
              <div>
                <img
                  src="/assets/tableLine.png"
                  alt=""
                  className={style.imegelines}
                />
              </div>
            </div>

            <div className={style.winPageBlock}>
              <div className={style.win}>
                <span className={style.winLineText}>Victory lines</span>
              </div>
            </div>
            <div className={style.cancellBlock}>
              <div className={style.cancellImage}>
                <NavLink to="/gameOne">
                  <img src={cancellSVG} alt="" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
