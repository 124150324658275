import React, { useEffect, useState } from "react";
import style from "./gameTwo.module.css";
import { useNavigate } from "react-router-dom";
import SlotMachineGameTwo from "./slotMachineGameTwo";
import { useDispatch, useSelector } from "react-redux";
import { decrement, increment } from "../../store/counter/counter";
import bets from "../../songs/bet.mp3";
import slot from "../../songs/slot.mp3";
import press from "../../songs/onpress.mp3";
import useSound from "../../utils/soundUtils";
import backSVG from "../../svg/back.svg";

import CryptoJS from "crypto-js";

export default function GameTwo() {
  const [startSpin, setStartSpin] = useState(false);
  const [winCount, setWinCounter] = useState(0);

  const [bonusGame, setBonusGame] = useState(0);

  const [freezeButton, setFreezeButton] = useState(false);

  const [bet, setBet] = useState(50);

  const dispatch = useDispatch();

  const { playSound } = useSound();

  const counter = useSelector((state) => state.counter.count);

  const navigate = useNavigate();

  const pageWinLineGameTwo = () => {
    playSound(press);
    const timer = setTimeout(() => {
      navigate("/winLineGameTwo");
    }, 500);

    return () => clearTimeout(timer);
  };

  const plusBet = () => {
    playSound(bets);

    setBet((prev) => {
      const newBet =
        prev < counter ? (prev + 50 < counter ? prev + 50 : counter) : counter;
      return newBet;
    });
  };

  const minusBet = () => {
    playSound(bets);
    setBet((prev) => {
      const newBet = prev - 50;

      if (newBet < 0) {
        return 0;
      }

      return newBet;
    });
  };

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const generateToken = (page, bet) => {
    const data = { page, bet };
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey
    ).toString();
    return encodeURIComponent(encryptedData);
  };

  useEffect(() => {
    if (bonusGame > 0 && bonusGame % 15 === 0) {
      const page = "gameTwo";
      const token = generateToken(page, bet);
      navigate(`/bonus/${token}`);
    }
  }, [bonusGame]);

  const goSpin = () => {
    if (bet > 0 && counter >= bet && !freezeButton) {
      setBonusGame(bonusGame + 1);

      if ((bonusGame + 1) % 15 === 0) {
        setStartSpin(false);
        setFreezeButton(false);
      } else {
        setFreezeButton(true);
        playSound(slot);
        dispatch(decrement(bet));
        setStartSpin(true);
      }
    }
  };

  useEffect(() => {
    if (winCount > 0) {
      setTimeout(() => {
        dispatch(increment(winCount));
      }, 1500);
      setTimeout(() => {
        setWinCounter(0);
      }, 2000);
    }
  }, [winCount]);

  const backPage = () => {
    navigate("/menu");
  };

  return (
    <div className={style.gameTwo}>
      <div className={style.blockBack}>
        <img
          src={backSVG}
          alt=""
          onClick={backPage}
          className={style.imageBack}
        />
      </div>
      <div className={style.gameTwoBlock}>
        <div className={style.navigationBlock}>
          <div className={style.winningLinesBlock}>
            <div className={style.winningLines}>
              <span
                className={style.winningLinesText}
                onClick={pageWinLineGameTwo}
              >
                winning lines
              </span>
            </div>
          </div>
          <div className={style.betBlock}>
            <div>
              <img
                src="/assets/minusGameTwo.png"
                alt=""
                className={style.imageNav}
                onClick={() => minusBet()}
              />
            </div>
            <div className={style.betCount}>
              <div className={style.betBlockButton}>
                <img
                  src="/assets/frameBetGameTwo.png"
                  alt=""
                  className={style.imageBet}
                />
                <div className={style.betTextBlock}>
                  <span className={style.betTextCount}>{bet}</span>
                </div>
              </div>
            </div>
            <div>
              <img
                src="/assets/plusGameTwo.png"
                alt=""
                className={style.imageNav}
                onClick={() => plusBet()}
              />
            </div>
          </div>
        </div>
        <div className={style.slotsBlock}>
          <div className={style.slots}>
            <img
              src="/assets/frameSlotsGameTwo.png"
              alt=""
              className={style.imageSlots}
            />
            <div className={style.slotsMachine}>
              <SlotMachineGameTwo
                startSpin={startSpin}
                setStartSpin={setStartSpin}
                bet={bet}
                setWinCounter={setWinCounter}
                setFreezeButton={setFreezeButton}
              />
            </div>
          </div>
        </div>

        <div className={style.countBlock}>
          <div className={style.counts}>
            <div className={style.balanceBlock}>
              <img
                src="/assets/frameCountGameTwo.png"
                alt=""
                className={style.imageCount}
              />
              <div className={style.balanceTextBlock}>
                <span className={style.balanceText}>SUM</span>
                <span className={style.balanceText}>{counter}</span>
              </div>
            </div>

            <div className={style.imageSpinBlock}>
              <img
                src="/assets/spinGameTwo.png"
                alt=""
                className={style.imageSpin}
                onClick={() => goSpin()}
              />
            </div>

            <div className={style.winBlock}>
              <img
                src="/assets/frameCountGameTwo.png"
                alt=""
                className={style.imageCount}
              />
              <div className={style.winTextBlock}>
                <span className={style.winText}>Victory</span>
                <span className={style.winText}>{winCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
