import React, { useEffect, useState } from "react";
import style from "./reward.module.css";
import RewardItem from "./rewardItem";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const initialDailyGifts = [
  { id: 1, count: 500, lock: false, received: false },
  { id: 2, count: 1000, lock: true, received: false },
  { id: 3, count: 1500, lock: true, received: false },
  { id: 4, count: 2000, lock: true, received: false },
  { id: 5, count: 2500, lock: true, received: false },
  { id: 6, count: 3000, lock: true, received: false },
  { id: 7, count: 3500, lock: true, received: false },
];

export default function Reward() {
  const navigate = useNavigate();

  const [dailyGifts, setDailyGifts] = useState(initialDailyGifts);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    initializeDailyGifts();
  }, []);

  useEffect(() => {
    if (isInitialized) {
      checkDailyLogin();
    }
  }, [isInitialized]);

  const initializeDailyGifts = () => {
    try {
      const storedGifts = localStorage.getItem("Gifts");
      if (storedGifts) {
        let gifts = JSON.parse(storedGifts);
        console.log("Stored gifts:", gifts); // Логирование для отладки
        setDailyGifts(gifts);
      } else {
        console.log("No gifts found, using initial state"); // Логирование для отладки
        setDailyGifts(initialDailyGifts);
      }
      setIsInitialized(true);
    } catch (error) {
      console.error("Error initializing daily gifts:", error);
    }
  };

  const saveDailyGifts = (gifts) => {
    try {
      localStorage.setItem("Gifts", JSON.stringify(gifts));
      console.log("Gifts saved:", gifts); // Добавьте логирование для отладки
    } catch (error) {
      console.error("Error saving gifts:", error);
    }
  };

  const checkDailyLogin = () => {
    try {
      const today = moment().format("YYYY-MM-DD");
      const lastVisitString = localStorage.getItem("lastVisit");
      const lastVisit = lastVisitString
        ? moment(lastVisitString).format("YYYY-MM-DD")
        : null;
      let visitCount = parseInt(localStorage.getItem("visitCount"), 10);

      if (isNaN(visitCount)) {
        visitCount = 0;
      }

      let newDataReward = null;

      let newVisitCount = visitCount;

      if (lastVisit) {
        const daysDifference = moment(today).diff(moment(lastVisit), "days");

        if (daysDifference === 1) {
          newVisitCount += 1;
        } else if (daysDifference > 1) {
          newVisitCount = 1;

          // Сброс наград
          const resetDailyGifts = dailyGifts.map((item, index) =>
            Object.assign({}, item, {
              lock: index === 0 ? null : true,
              received: false,
            })
          );

          newDataReward = resetDailyGifts;
          saveDailyGifts(resetDailyGifts);
        }
      } else {
        newVisitCount = 1;
      }

      localStorage.setItem("lastVisit", today);
      localStorage.setItem("visitCount", newVisitCount.toString());

      console.log("newVisitCount", newVisitCount);

      const updatedDailyGifts = dailyGifts.map((item) => {
        if (item.id <= newVisitCount) {
          return Object.assign({}, item, { lock: null });
        }
        return Object.assign({}, item, { lock: true, received: false });
      });

      setDailyGifts(newDataReward !== null ? newDataReward : updatedDailyGifts);
      saveDailyGifts(
        newDataReward !== null ? newDataReward : updatedDailyGifts
      );
    } catch (error) {
      console.error("Error checking daily login:", error);
    }
  };

  const markGiftAsReceived = (day) => {
    const updatedDailyGifts = dailyGifts.map((gift) => {
      if (gift.id <= day) {
        return Object.assign({}, gift, { received: true, lock: null });
      }
      return Object.assign({}, gift, { lock: true, received: false });
    });

    setDailyGifts(updatedDailyGifts);
    saveDailyGifts(updatedDailyGifts);
    console.log("Gift marked as received:", updatedDailyGifts); // Логирование для отладки
  };

  return (
    <div className={style.reward}>
      <div className={style.rewardBlock}>
        <div className={style.dailyReward}>
          <div>
            <img
              src="./assets/bigFrame.png"
              alt=""
              className={style.imageFrame}
            />
          </div>
          <div className={style.blockTitle}>
            <div className={style.blockTitlePos}>
              <span className={style.textTitle}>Daily reward</span>
            </div>
          </div>
          <div className={style.getReward}>
            <div className={style.blockGetReward}>
              {dailyGifts.map((item) => (
                <RewardItem
                  key={item.id}
                  day={item.id}
                  count={item.count}
                  lock={item.lock}
                  received={item.received}
                  markGiftAsReceived={markGiftAsReceived}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
