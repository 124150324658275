import React, { useEffect, useState } from "react";
import style from "./privacy.module.css";
import { useNavigate } from "react-router-dom";
import useSound from "../../utils/soundUtils";
import press from "../../songs/onpress.mp3";
import moment from "moment";

export default function Privacy() {
  const [giftTakenToday, setGiftTakenToday] = useState(false);
  const [completedDaily, setCompletedDaily] = useState(false);
  const [visit, setVisit] = useState(0);
  const [privacyAccept, setPrivacyAccept] = useState(false);

  const { playSound } = useSound();

  const navigate = useNavigate();

  useEffect(() => {
    checkGiftTakenToday();
  }, []);

  const checkGiftTakenToday = () => {
    try {
      const today = moment().format("YYYY-MM-DD");
      const lastVisitString = localStorage.getItem("lastVisit");
      const lastVisit = lastVisitString
        ? moment(lastVisitString).format("YYYY-MM-DD")
        : null;
      const data = localStorage.getItem("completed");
      const completed = JSON.parse(data);

      const privacyData = localStorage.getItem("privacy");

      if (privacyData) {
        const parsedPrivacy = JSON.parse(privacyData);

        setPrivacyAccept(!!parsedPrivacy);
      }

      let visitCount = parseInt(localStorage.getItem("visitCount"), 10);

      if (isNaN(visitCount)) {
        setVisit(0);
      } else {
        setVisit(visitCount);
      }

      if (lastVisit) {
        const daysDifference = moment(today).diff(moment(lastVisit), "days");
        console.log("Days difference:", daysDifference);

        if (daysDifference === 0) {
          if (completed === true) {
            setCompletedDaily(true);
            setGiftTakenToday(true);
          } else {
            setCompletedDaily(false);
            setGiftTakenToday(false);
          }
        } else {
          setGiftTakenToday(false);
          setCompletedDaily(false);
          localStorage.setItem("completed", JSON.stringify(false));
        }
      } else {
        localStorage.setItem("completed", JSON.stringify(false));
      }
    } catch (error) {
      console.error("Error checking if gift was taken today:", error);
    }
  };

  const switchPage = () => {
    playSound(press);

    navigate(
      giftTakenToday
        ? "/intro"
        : completedDaily
        ? "/intro"
        : visit >= 7
        ? "/intro"
        : "/reward"
    );
    applyPrivacy(true);
  };

  const applyPrivacy = (result) => {
    try {
      localStorage.setItem("privacy", JSON.stringify(result));
    } catch (error) {
      console.error("Error saving privacy:", error);
    }
  };

  const handleRedirect = () => {
    navigate("/policy");
  };

  return (
    <div className={style.privacy}>
      <div className={style.privacyBlock}>
        <div>
          <span className={style.privacyText} onClick={() => handleRedirect()}>
            Privacy policy
          </span>
        </div>
        {!privacyAccept && (
          <div className={style.buttonBlock}>
            <img
              src="/assets/buttonAccept.png"
              alt=""
              className={style.buttonImage}
              onClick={() => switchPage()}
            />
          </div>
        )}
      </div>
    </div>
  );
}
