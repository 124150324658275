import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import style from "./slotMachine.module.css";

import win from "../../songs/win.mp3";
import lose from "../../songs/lose.mp3";
import useSound from "../../utils/soundUtils";

const frames = [
  {
    img: "/assets/cherryGameTwo.png",
    imgBlack: "/assets/cherryBlackGameTwo.png",
    alt: "cherry",
    id: 1,
  },

  {
    img: "/assets/pearGameTwo.png",
    imgBlack: "/assets/pearBlackGameTwo.png",
    alt: "pear",
    id: 2,
  },

  {
    img: "/assets/orangeGameTwo.png",
    imgBlack: "/assets/orangeBlackGameTwo.png",
    alt: "orange",
    id: 3,
  },
  {
    img: "/assets/lemonGameTwo.png",
    imgBlack: "/assets/lemonBlackGameTwo.png",
    alt: "lemon",
    id: 4,
  },

  {
    img: "/assets/starsGameTwo.png",
    imgBlack: "/assets/starsBlackGameTwo.png",
    alt: "stars",
    id: 5,
  },

  {
    img: "/assets/sevenGameTwo.png",
    imgBlack: "/assets/sevenBlackGameTwo.png",
    alt: "seven",
    id: 6,
  },
];

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const SlotMachineGameTwo = ({
  startSpin,
  setStartSpin,
  bet,
  setWinCounter,
  setFreezeButton,
}) => {
  const [slots, setSlots] = useState([[], [], []]);
  const [spinKeys, setSpinKeys] = useState([0, 1, 2]);
  const [activeSlot, setActiveSlot] = useState(-1);

  const [lines, setLines] = useState([]);
  const [hideLine, setHideLine] = useState(false);

  const [countResultLine, setCountResultLine] = useState([]);
  const [countResultDiagonal, setCountResultDiagonal] = useState([]);

  const [gameResult, setGameResult] = useState(null);

  const increaseWinProbability = 0.3;

  const { playSound } = useSound();

  useEffect(() => {
    resetGame();
  }, []);

  const checkWin = (result) => {
    const { slot1, slot2, slot3 } = result;
    let win = false;
    const newLines = [];

    let rows = [];
    let diagonal = [];

    let countLine = [];
    let countDiagonal = [];

    let resWin = [];

    // Check rows
    for (let i = 0; i < 3; i++) {
      if (slot1[i].id === slot2[i].id && slot2[i].id === slot3[i].id) {
        win = true;
        rows.push(slot1[i].id);
        newLines.push({ type: "row", index: i });

        let res = {
          slot1: {
            id: slot1[i].id,
            index: i,
          },
          slot2: {
            id: slot2[i].id,
            index: i,
          },
          slot3: {
            id: slot3[i].id,
            index: i,
          },
        };

        countLine.push(res);
      }
    }

    setCountResultLine(countLine);

    // Check diagonals
    if (slot1[0].id === slot2[1].id && slot2[1].id === slot3[2].id) {
      win = true;
      diagonal.push(slot1[0].id);

      newLines.push({ type: "diagonal", index: 0 });

      let res = {
        slot1: {
          id: slot1[0].id,
          index: 0,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[2].id,
          index: 2,
        },
      };

      countDiagonal.push(res);
    }
    if (slot1[2].id === slot2[1].id && slot2[1].id === slot3[0].id) {
      win = true;
      diagonal.push(slot3[0].id);

      newLines.push({ type: "diagonal", index: 1 });

      let res = {
        slot1: {
          id: slot1[2].id,
          index: 2,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[0].id,
          index: 0,
        },
      };

      countDiagonal.push(res);
    }

    // if (rows) {
    //   resWin.push(...rows);
    // }
    // if (diagonal) {
    //   resWin.push(...diagonal);
    // }

    if (rows) {
      resWin = resWin.concat(rows);
    }
    if (diagonal) {
      resWin = resWin.concat(diagonal);
    }

    setGameResult(resWin);

    setCountResultDiagonal(countDiagonal);

    setLines(newLines);
    return win;
  };

  const resetGame = () => {
    const newSlots = [];
    for (let i = 0; i < 3; i++) {
      // newSlots.push(shuffleArray([...frames]).slice(0, 3));

      // Клонируем массив с помощью метода slice
      const clonedFrames = frames.slice();

      // Перемешиваем клонированный массив
      const shuffledFrames = shuffleArray(clonedFrames);

      // Обрезаем массив до 3 элементов
      const slicedFrames = shuffledFrames.slice(0, 3);

      // Добавляем в newSlots
      newSlots.push(slicedFrames);
    }
    setSlots(newSlots);
  };

  const handleSpin = () => {
    setHideLine(false);
    setCountResultLine([]);
    setCountResultDiagonal([]);

    const newSlots = [];
    // newSlots.push(shuffleArray([...frames]).slice(0, 3));

    // Клонируем массив с помощью метода slice
    const clonedFrames = frames.slice();

    // Перемешиваем клонированный массив
    const shuffledFrames = shuffleArray(clonedFrames);

    // Обрезаем массив до 3 элементов
    const slicedFrames = shuffledFrames.slice(0, 3);

    // Добавляем в newSlots
    newSlots.push(slicedFrames);

    for (let i = 1; i < 3; i++) {
      let currentSlot;

      // // С вероятностью increaseWinProbability делаем слоты совпадающими с предыдущими
      // if (Math.random() < increaseWinProbability) {
      //   currentSlot = [...newSlots[i - 1]]; // Копируем предыдущий слот
      //   if (Math.random() < increaseWinProbability) {
      //     // Случайно заменяем одно изображение в слоте
      //     currentSlot[Math.floor(Math.random() * 3)] = shuffleArray([
      //       ...frames,
      //     ])[0];
      //   }
      // } else {
      //   currentSlot = shuffleArray([...frames]).slice(0, 3); // Случайное заполнение
      // }

      if (Math.random() < increaseWinProbability) {
        currentSlot = newSlots[i - 1].concat(); // Копируем предыдущий слот
        if (Math.random() < increaseWinProbability) {
          // Случайно заменяем одно изображение в слоте
          const newFrames = frames.slice(); // Копируем массив frames
          currentSlot[Math.floor(Math.random() * 3)] =
            shuffleArray(newFrames)[0];
        }
      } else {
        currentSlot = shuffleArray(frames.slice()).slice(0, 3); // Случайное заполнение
      }

      newSlots.push(currentSlot);
    }
    setSpinKeys((prevKeys) => prevKeys.map((key) => key + 1));

    setTimeout(() => {
      setSlots(newSlots);
    }, 1000);

    const result = {
      slot1: newSlots[0],
      slot2: newSlots[1],
      slot3: newSlots[2],
    };

    setTimeout(() => {
      if (checkWin(result)) {
        playSound(win);
        setFreezeButton(false);
      } else {
        playSound(lose);
        setFreezeButton(false);
      }
      setStartSpin(false);
      setHideLine(true);
    }, 1500);

    setActiveSlot(0);
  };

  useEffect(() => {
    if (startSpin) {
      handleSpin();
    }
  }, [startSpin]);

  useEffect(() => {
    if (Array.isArray(gameResult)) {
      let res = [];

      gameResult.forEach((item) => {
        let winPercentage = 0;

        switch (item) {
          case 1:
            winPercentage = 1.2;
            break;
          case 2:
            winPercentage = 1.6;
            break;
          case 3:
            winPercentage = 1.8;
            break;
          case 4:
            winPercentage = 2.0;
            break;
          case 5:
            winPercentage = 3.0;
            break;
          case 6:
            winPercentage = 5.0;
            break;
          default:
            winPercentage = 0;
            break;
        }

        const winAmount = bet * winPercentage;
        res.push(winAmount);
      });

      const sumNumbers = res.reduce((a, b) => a + b, 0);

      setWinCounter(sumNumbers);
    }
  }, [gameResult]);

  useEffect(() => {
    if (activeSlot >= 0 && activeSlot < slots.length) {
      const timer = setTimeout(() => {
        setActiveSlot((prev) => prev + 1);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [activeSlot]);

  const renderSlot = (slotIndex, slot) => {
    const animatedImages = Array.from(
      { length: 30 },
      (_, index) => slots[slotIndex][index % slots[slotIndex].length]
    );

    const getImageStyle = (slotIndex, indexInSlot) => {
      let showBack = false;

      // Проверяем все линии
      countResultLine.forEach((countLine) => {
        switch (slotIndex) {
          case 0:
            if (countLine.slot1 && countLine.slot1.index === indexInSlot) {
              showBack = true;
            }
            break;
          case 1:
            if (countLine.slot2 && countLine.slot2.index === indexInSlot) {
              showBack = true;
            }
            break;
          case 2:
            if (countLine.slot3 && countLine.slot3.index === indexInSlot) {
              showBack = true;
            }
            break;
          case 3:
            if (countLine.slot4 && countLine.slot4.index === indexInSlot) {
              showBack = true;
            }
            break;
          default:
            break;
        }
      });

      // Проверяем диагональные линии
      if (countResultDiagonal) {
        countResultDiagonal.length > 0 &&
          countResultDiagonal.forEach((countDiagonal) => {
            switch (slotIndex) {
              case 0:
                if (
                  countDiagonal.slot1 &&
                  countDiagonal.slot1.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              case 1:
                if (
                  countDiagonal.slot2 &&
                  countDiagonal.slot2.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              case 2:
                if (
                  countDiagonal.slot3 &&
                  countDiagonal.slot3.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              case 3:
                if (
                  countDiagonal.slot4 &&
                  countDiagonal.slot4.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              default:
                break;
            }
          });
      }

      return { showBack };
    };

    const vh = window.innerHeight / 100;
    const animationHeight = -98 * vh;

    return (
      <div className={style.slotBlock} key={slotIndex}>
        <motion.div
          key={spinKeys[slotIndex]}
          className={style.slotsInner}
          initial={{ y: animationHeight }}
          animate={{ y: activeSlot >= slotIndex ? 0 : animationHeight }}
          transition={{ duration: 1, ease: "linear" }}
        >
          {animatedImages.map((image, index) => {
            const { showBack } = getImageStyle(slotIndex, index % slot.length);
            return (
              <div className={style.slotsItem} key={index}>
                {showBack ? (
                  <>
                    <img
                      src={image && image.img}
                      alt={image && image.alt}
                      className={style.slotsItemImage}
                    />
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: -10,
                      }}
                    >
                      <img
                        src="/assets/lightGameTwo.png"
                        alt=""
                        className={style.lightSlotsImage}
                      />
                    </motion.div>
                  </>
                ) : countResultLine.length > 0 ||
                  countResultDiagonal.length > 0 ? (
                  <>
                    <img
                      src={image && image.imgBlack}
                      alt={image && image.alt}
                      className={style.slotsItemImage}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={image && image.img}
                      alt={image && image.alt}
                      className={style.slotsItemImage}
                    />
                  </>
                )}
              </div>
            );
          })}
        </motion.div>
      </div>
    );
  };

  const renderLines = () => {
    return lines.map((line, index) => {
      if (line.type === "row") {
        return (
          <div
            key={index}
            style={{
              position: "absolute",
              height: 5,
              backgroundColor: "#DBFF00",
              alignItems: "center",
              top: `${15 + line.index * 35}%`,
              left: "12%",
              width: "80vw",
            }}
          />
        );
      }
      if (line.type === "diagonal") {
        if (line.index === 0) {
          return (
            <div
              key={index}
              style={{
                transform: "rotate(21deg) translateX(50px)",
                width: "74vw",
                height: 5,
                position: "absolute",
                backgroundColor: "#DBFF00",
                top: "38%",
                left: "2%",
                right: 0,
                zIndex: -5,
              }}
            />
          );
        } else {
          return (
            <div
              key={index}
              style={{
                transform: "rotate(-21deg) translateX(50px)",
                width: "74vw",
                height: 5,
                position: "absolute",
                backgroundColor: "#DBFF00",
                top: "61%",
                left: "5%",
                right: 0,
                zIndex: -5,
              }}
            />
          );
        }
      }
    });
  };

  return (
    <div className={style.slotsMachine}>
      {hideLine && renderLines()}
      {slots.map((slot, index) => renderSlot(index, slot))}
    </div>
  );
};

export default SlotMachineGameTwo;
