import React, { useState, useEffect } from "react";
import style from "./orangeAnimation.module.css";
import frame1 from "../../sprites/spriteOrange/Mask group-1.png";
import frame2 from "../../sprites/spriteOrange/Mask group-2.png";
import frame3 from "../../sprites/spriteOrange/Mask group-3.png";
import frame4 from "../../sprites/spriteOrange/Mask group-4.png";
import frame5 from "../../sprites/spriteOrange/Mask group-5.png";
import frame6 from "../../sprites/spriteOrange/Mask group-6.png";
import frame7 from "../../sprites/spriteOrange/Mask group-7.png";
import frame8 from "../../sprites/spriteOrange/Mask group-8.png";
import frame9 from "../../sprites/spriteOrange/Mask group-9.png";
import frame10 from "../../sprites/spriteOrange/Mask group-10.png";
import frame11 from "../../sprites/spriteOrange/Mask group-11.png";
import frame12 from "../../sprites/spriteOrange/Mask group-12.png";
import frame13 from "../../sprites/spriteOrange/Mask group-13.png";
import frame14 from "../../sprites/spriteOrange/Mask group-14.png";
import frame15 from "../../sprites/spriteOrange/Mask group-15.png";
import frame16 from "../../sprites/spriteOrange/Mask group-16.png";
import frame17 from "../../sprites/spriteOrange/Mask group-17.png";
import frame18 from "../../sprites/spriteOrange/Mask group-18.png";
import frame19 from "../../sprites/spriteOrange/Mask group-19.png";
import frame20 from "../../sprites/spriteOrange/Mask group-20.png";
import frame21 from "../../sprites/spriteOrange/Mask group-21.png";
import frame22 from "../../sprites/spriteOrange/Mask group-22.png";
import frame23 from "../../sprites/spriteOrange/Mask group-23.png";
import frame24 from "../../sprites/spriteOrange/Mask group-24.png";
import frame25 from "../../sprites/spriteOrange/Mask group-25.png";
import frame26 from "../../sprites/spriteOrange/Mask group-26.png";
import frame27 from "../../sprites/spriteOrange/Mask group-27.png";
import frame28 from "../../sprites/spriteOrange/Mask group-28.png";
import frame29 from "../../sprites/spriteOrange/Mask group-29.png";
import frame30 from "../../sprites/spriteOrange/Mask group-30.png";
import frame31 from "../../sprites/spriteOrange/Mask group-31.png";
import frame32 from "../../sprites/spriteOrange/Mask group.png";

const OrangeAnimation = () => {
  const frames = [
    frame1,
    frame2,
    frame3,
    frame4,
    frame5,
    frame6,
    frame7,
    frame8,
    frame9,
    frame10,
    frame11,
    frame12,
    frame13,
    frame14,
    frame15,
    frame16,
    frame17,
    frame18,
    frame19,
    frame20,
    frame21,
    frame22,
    frame23,
    frame24,
    frame25,
    frame26,
    frame27,
    frame28,
    frame29,
    frame30,
    frame31,
    frame32,
  ];
  const [currentFrame, setCurrentFrame] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFrame((prevFrame) => (prevFrame + 1) % frames.length);
    }, 150); // 10 frames per second

    return () => clearInterval(interval);
  }, [frames.length]);

  return (
    <div className={style.spriteAnimation}>
      {frames.map((frame, index) => (
        <img
          key={index}
          src={frame}
          alt={`Frame ${index}`}
          className={`${style.frame} ${
            index === currentFrame ? style.visible : style.hidden
          }`}
          style={{ width: "25vw", height: "6vh" }}
        />
      ))}
    </div>
  );
};

export default OrangeAnimation;
