import React, { useEffect, useState } from "react";
import style from "./bonusGame.module.css";
import AnimatedWheel from "./AnimatedWheel";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { increment } from "../../store/counter/counter";
import useSound from "../../utils/soundUtils";
import runweel from "../../songs/runweel.mp3";
import CryptoJS from "crypto-js";
import { motion } from "framer-motion";

export default function BonusGame() {
  const [spinStart, setSpinStart] = useState(false);
  const [spinResult, setSpinResult] = useState(null);

  const [buttonClick, setButtonClick] = useState(false);

  const [resCount, setResCount] = useState(null);

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const decryptToken = (token) => {
    const bytes = CryptoJS.AES.decrypt(decodeURIComponent(token), secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  const { token } = useParams();
  const { page, bet } = decryptToken(token);

  const dispatch = useDispatch();

  const { playSound } = useSound();

  useEffect(() => {
    if (!buttonClick) {
      setButtonClick(true);
    }
  }, []);

  const startSpinWeel = () => {
    if (buttonClick) {
      setButtonClick(false);
      playSound(runweel);
      setSpinStart(true);
    }
  };

  useEffect(() => {
    if (spinResult) {
      let count = 0;
      switch (spinResult) {
        case "x1":
          count = 1;
          break;
        case "x2":
          count = 2;
          break;
        case "x3":
          count = 3;
          break;
        default:
          break;
      }
      let res = bet * count;

      console.log("res", res);

      setTimeout(() => {
        setResCount(res);
      }, 500);

      dispatch(increment(res));
    }
  }, [spinResult]);

  return (
    <div className={style.bonus}>
      {resCount !== null && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(55, 55, 55, 0.4)",
            zIndex: 40,
          }}
        >
          <div style={{ position: "relative" }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className={style.textRes}>+{resCount}</span>
            </motion.div>
          </div>
        </div>
      )}
      <div className={style.bonusBlock}>
        <div className={style.bonusComponents}>
          <div>
            <img
              src="/assets/bigFrame.png"
              alt=""
              className={style.imageFrame}
            />
          </div>
          <div className={style.blockTitle}>
            <div className={style.blockTitlePos}>
              <span className={style.textTitle}>Try your luck</span>
            </div>
          </div>
          <div className={style.blockWeel}>
            <div className={style.weelComponents}>
              <div className={style.arrow}>
                <img
                  src="/assets/arrow.png"
                  alt=""
                  className={style.imageArrow}
                />
              </div>
              <div className={style.weel}>
                <AnimatedWheel
                  spinStart={spinStart}
                  setSpinStart={setSpinStart}
                  setSpinResult={setSpinResult}
                  page={page}
                  setResCount={setResCount}
                />
              </div>
            </div>
          </div>
          <div className={style.blockSpin}>
            <img
              src="/assets/spin_def.png"
              alt=""
              className={style.imageSpin}
              onClick={startSpinWeel}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
