import React, { useEffect } from "react";
import style from "./menu.module.css";
import { useNavigate } from "react-router-dom";

import press from "../../songs/onpress.mp3";
import useSound from "../../utils/soundUtils";

export default function Menu() {
  const navigate = useNavigate();

  const { playSound } = useSound();

  const pageSettings = () => {
    playSound(press);
    const timer = setTimeout(() => {
      navigate("/settings");
    }, 500);

    return () => clearTimeout(timer);
  };

  const pageGameOne = () => {
    playSound(press);
    const timer = setTimeout(() => {
      navigate("/gameOne");
    }, 500);

    return () => clearTimeout(timer);
  };

  const pageGameTwo = () => {
    playSound(press);
    const timer = setTimeout(() => {
      navigate("/gameTwo");
    }, 500);

    return () => clearTimeout(timer);
  };

  const pageGameTree = () => {
    playSound(press);
    const timer = setTimeout(() => {
      navigate("/gameTree");
    }, 500);

    return () => clearTimeout(timer);
  };

  return (
    <div className={style.menu}>
      <div className={style.menuBlock}>
        <div className={style.blockSettings}>
          <img
            src="/assets/buttonSettings.png"
            alt=""
            className={style.imageSettings}
            onClick={pageSettings}
          />
        </div>

        <div className={style.gamesBlock}>
          <div onClick={pageGameOne}>
            <img
              src="/assets/menuCombo.png"
              alt=""
              className={style.imageFrame}
            />
          </div>
        </div>

        <div className={style.gamesBlock}>
          <div onClick={pageGameTwo}>
            <img
              src="/assets/menuFruits.png"
              alt=""
              className={style.imageFrame}
            />
          </div>
        </div>

        <div className={style.gamesBlock}>
          <div onClick={pageGameTree}>
            <img
              src="/assets/menuStars.png"
              alt=""
              className={style.imageFrame}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
