import React from "react";
import style from "./rewardItem.module.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { increment } from "../../store/counter/counter";
import rewardSVG from "../../svg/reward.svg";

import press from "../../songs/win.mp3";
import useSound from "../../utils/soundUtils";

export default function RewardItem({
  day,
  count,
  lock,
  received,
  markGiftAsReceived,
}) {
  const dispatch = useDispatch();

  const { playSound } = useSound();

  const navigate = useNavigate();

  const getCount = () => {
    if (!lock && received === false) {
      playSound(press);

      saveSevenGift(true);
      dispatch(increment(count));
      markGiftAsReceived(day);
      setTimeout(() => {
        navigate("/intro");
      }, 2000);
    }
  };

  const saveSevenGift = (completed) => {
    localStorage.setItem("completed", JSON.stringify(completed));
  };

  return (
    <div>
      {day === 7 ? (
        <div className={style.rewardItemTwo} onClick={getCount}>
          <img
            src="./assets/frameLongSmoll.png"
            alt=""
            className={style.imageFrameLongSmoll}
            style={{ opacity: lock ? "0.25" : "1" }}
          />

          <div className={style.textRewardTwo}>
            <span
              className={style.textDay}
              style={{ opacity: lock ? "0.25" : "1" }}
            >
              Day {day}
            </span>

            <span
              className={style.textCount}
              style={{ opacity: lock ? "0.25" : "1" }}
            >
              +{count}
            </span>
          </div>
          {received && (
            <div className={style.rewardImgSVGTwo}>
              <img src={rewardSVG} alt="" />
            </div>
          )}
        </div>
      ) : (
        <div className={style.rewardItem} onClick={getCount}>
          <img
            src="./assets/frameForMenu.png"
            alt=""
            className={style.imageFrameSmoll}
            style={{ opacity: lock ? "0.25" : "1" }}
          />
          <div className={style.textReward}>
            <span
              className={style.textDay}
              style={{ opacity: lock ? "0.25" : "1" }}
            >
              Day {day}
            </span>

            <span
              className={style.textCount}
              style={{ opacity: lock ? "0.25" : "1" }}
            >
              +{count}
            </span>
          </div>
          {received && (
            <div className={style.rewardImgSVG}>
              <img src={rewardSVG} alt="" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
