import React from "react";
import style from "./gameTwo.module.css";
import cancellSVG from "../../svg/cancell.svg";
import { NavLink } from "react-router-dom";

export default function WinLineGameTwo() {
  return (
    <div className={style.gameTwo}>
      <div className={style.gameTwoBlock}>
        <div className={style.winLineBlock}>
          <div className={style.winLine}>
            <div className={style.diagonalBlock}>
              <div>
                <img
                  src="/assets/oneDiagonal.png"
                  alt=""
                  className={style.imegelines}
                />
              </div>
              <div>
                <img
                  src="/assets/twoDiagonal.png"
                  alt=""
                  className={style.imegelines}
                />
              </div>
            </div>
            <div className={style.lineBlock}>
              <div>
                <img
                  src="/assets/oneLine.png"
                  alt=""
                  className={style.imegelines}
                />
              </div>
              <div>
                <img
                  src="/assets/twoLine.png"
                  alt=""
                  className={style.imegelines}
                />
              </div>
              <div>
                <img
                  src="/assets/treeLine.png"
                  alt=""
                  className={style.imegelines}
                />
              </div>
            </div>
            <div className={style.winPageBlock}>
              <div className={style.win}>
                <span className={style.winLineText}>Victory lines</span>
              </div>
            </div>
            <div className={style.cancellBlock}>
              <div className={style.cancellImage}>
                <NavLink to="/gameTwo">
                  <img src={cancellSVG} alt="" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
