import React, { useState, useEffect } from "react";
import style from "./settings.module.css";
import musicSVG from "../../svg/music.svg";
import soundSVG from "../../svg/sound.svg";
import backSVG from "../../svg/back.svg";

import reset from "../../songs/resetscore.mp3";

import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import { useDispatch, useSelector } from "react-redux";
import { resetCount } from "../../store/counter/counter";
import { getEffectData } from "../../store/sounds/sounds";
import useSound from "../../utils/soundUtils";
import { useNavigate } from "react-router-dom";

export default function Settings() {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [popupResetScore, setPopupResetScore] = useState(false);

  const effectsData = useSelector((state) => state.effects.effectsData);

  const { playSound } = useSound();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = () => {
      const effects = localStorage.getItem("effects");
      if (effects) {
        const data = JSON.parse(effects);

        let musicWV = volumeToPosition(data.music ? data.music.percent : 0);
        let soundVW = volumeToPosition(data.sound ? data.sound.percent : 0);
        let sound = parseFloat(soundVW);
        let music = parseFloat(musicWV);

        set1({ x: sound });
        set2({ x: music });
      }
      const handleResize = () => setViewportWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    };

    loadData();
  }, []);

  const vwToPx = (vw) => (vw / 100) * viewportWidth;

  const maxVw = 40; // 40vw
  const minVw = 0; // 0vw

  // Позиции в пикселях
  const maxPos = vwToPx(maxVw);
  const minPos = vwToPx(minVw);

  const [{ x: x1 }, set1] = useSpring(() => ({ x: vwToPx(0) }));
  const [{ x: x2 }, set2] = useSpring(() => ({ x: vwToPx(0) }));

  const volumeToVw = (volume) => {
    // Ограничиваем volume в диапазоне от 0 до 1
    const clampedVolume = Math.min(Math.max(volume, 0), 1);

    // Преобразуем volume в значение vw
    const vw = clampedVolume * (maxVw - minVw) + minVw;

    return parseFloat(vw.toFixed(2)); // Возвращаем значение, округленное до двух знаков после запятой
  };

  // Преобразование позиции в объем (volume)
  const positionToVolume = (position) => {
    const volume = (position - minPos) / (maxPos - minPos);
    return Math.min(Math.max(volume, 0), 1).toFixed(2);
  };

  const volumeToPosition = (volume) => {
    // Преобразуем volume в vw
    const vw = volumeToVw(volume);

    // Преобразуем vw в пиксели
    const positionPx = vwToPx(vw);

    return positionPx;
  };

  const bindOne = useDrag(({ movement: [mx], memo = x1.get() }) => {
    let newPos = memo + mx;
    if (newPos < minPos) newPos = minPos;
    if (newPos > maxPos) newPos = maxPos;
    set1({ x: newPos });

    console.log("before", newPos);

    const volume = positionToVolume(newPos);

    console.log("before volume", volume);

    switchEffects({
      sound: { percent: volume },
      music: {
        percent:
          effectsData && effectsData.music ? effectsData.music.percent : 0,
      },
    });

    dispatch(
      getEffectData({
        sound: { percent: volume },
        music: {
          percent:
            effectsData && effectsData.music ? effectsData.music.percent : 0,
        },
      })
    );

    return memo;
  });

  const bindTwo = useDrag(({ movement: [mx], memo = x2.get() }) => {
    let newPos = memo + mx;
    if (newPos < minPos) newPos = minPos;
    if (newPos > maxPos) newPos = maxPos;
    set2({ x: newPos });

    const volume = positionToVolume(newPos);

    switchEffects({
      sound: {
        percent:
          effectsData && effectsData.sound ? effectsData.sound.percent : 0,
      },
      music: { percent: volume },
    });

    dispatch(
      getEffectData({
        sound: {
          percent:
            effectsData && effectsData.sound ? effectsData.sound.percent : 0,
        },
        music: { percent: volume },
      })
    );

    return memo;
  });

  const switchEffects = (data) => {
    let effects = JSON.stringify(data);
    try {
      localStorage.setItem("effects", effects);
    } catch (error) {
      console.error(error);
    }
  };

  const resetScore = () => {
    playSound(reset);
    dispatch(resetCount());
    localStorage.removeItem("countStore");
    setPopupResetScore(true);
    setTimeout(() => {
      setPopupResetScore(false);
    }, 2000);
  };

  const backPage = () => {
    navigate("/menu");
  };

  return (
    <div className={style.settings}>
      <div
        className={`${style.popupResScore} ${
          popupResetScore ? style.active : ""
        }`}
      >
        <div className={style.popupResScoreBlock}>
          <span className={style.textPopup}>Clean score!</span>
        </div>
      </div>

      <div className={style.blockBack}>
        <img
          src={backSVG}
          alt=""
          onClick={backPage}
          style={{ width: "10vw", height: "auto" }}
        />
      </div>
      <div className={style.settingsBlock}>
        <div className={style.settingsComponents}>
          <div>
            <img
              src="./assets/bigFrame.png"
              alt=""
              className={style.imageFrame}
            />
          </div>
          <div className={style.blockTitle}>
            <div className={style.blockTitlePos}>
              <span className={style.textTitle}>Settings</span>
            </div>
          </div>
          <div className={style.blockMusic}>
            <div className={style.music}>
              <div>
                <img src={soundSVG} alt="" />
              </div>
              <div>
                <div className={style.border}>
                  <div className={style.firstLayer} />
                  <animated.div
                    style={{
                      width: x1.to((x) => `${x}px`),
                      height: "3vh",
                      backgroundColor: "#ffc700",
                      borderRadius: "20px",
                      zIndex: 10,
                      position: "absolute",
                      left: 0,
                    }}
                  />
                  <animated.div
                    {...bindOne()}
                    style={{
                      position: "absolute",
                      left: x1.to((x) => `${x - vwToPx(2)}px`),
                      touchAction: "none",
                      zIndex: 30,
                      top: "-3px",
                    }}
                  >
                    <img
                      src="./assets/scroll.png"
                      alt=""
                      className={style.imageScroll}
                    />
                  </animated.div>
                </div>
              </div>
            </div>

            <div className={style.sound}>
              <div>
                <img src={musicSVG} alt="" />
              </div>
              <div>
                <div className={style.border}>
                  <div className={style.firstLayer} />
                  <animated.div
                    style={{
                      width: x2.to((x) => `${x}px`),
                      height: "3vh",
                      backgroundColor: "#ffc700",
                      borderRadius: "20px",
                      zIndex: 10,
                      position: "absolute",
                      left: 0,
                    }}
                  />
                  <animated.div
                    {...bindTwo()}
                    style={{
                      position: "absolute",
                      left: x2.to((x) => `${x - vwToPx(2)}px`),
                      touchAction: "none",
                      zIndex: 30,
                      top: "-3px",
                    }}
                  >
                    <img
                      src="./assets/scroll.png"
                      alt=""
                      className={style.imageScroll}
                    />
                  </animated.div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.blockReset}>
            <div className={style.reset}>
              <span className={style.text} onClick={resetScore}>
                Reset score
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
