export const RenderLines = ({ lines, gameResultRow, gameResultDiagonal }) => {
  console.log("gameResultDiagonal", gameResultDiagonal);
  console.log("lines", lines);

  return lines.map((line, index) => {
    if (line.type === "row") {
      if (Array.isArray(gameResultRow)) {
        return gameResultRow.map((resRow, rowIndex) => {
          if (line.index === resRow.index) {
            if (resRow.count === 3) {
              return (
                <div
                  key={`row-${index}-${rowIndex}`}
                  style={{
                    position: "absolute",
                    height: 5,
                    backgroundColor: "#FAFF00",
                    alignItems: "center",
                    top: `${15 + line.index * 35}%`,
                    left: line.slot === "slot1" ? "10%" : "50%",
                    width: "30vw",
                  }}
                />
              );
            }
            if (resRow.count === 4) {
              return (
                <div
                  key={`row-${index}-${rowIndex}`}
                  style={{
                    position: "absolute",
                    height: 5,
                    backgroundColor: "#FAFF00",
                    alignItems: "center",
                    top: `${15 + line.index * 35}%`,
                    left: line.slot === "slot1" ? "10%" : "30%",
                    width: "44vw",
                  }}
                />
              );
            }
            if (resRow.count === 5) {
              return (
                <div
                  key={`row-${index}-${rowIndex}`}
                  style={{
                    position: "absolute",
                    height: 5,
                    backgroundColor: "#FAFF00",
                    alignItems: "center",
                    top: `${15 + line.index * 35}%`,
                    left: "10%",
                    width: "60vw",
                  }}
                />
              );
            }
          }
          return null;
        });
      }
    }

    if (line.type === "diagonal") {
      if (line.index === 0) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div
                  key={`row-${index}-${diagIndex}`}
                  style={{
                    position: "absolute",
                    height: 5,
                    backgroundColor: "#FAFF00",
                    alignItems: "center",
                    transform: "rotate(42deg) translateX(50px)",
                    width: "45vw",
                    height: 5,
                    position: "absolute",
                    backgroundColor: "#FAFF00",
                    top: "28%",
                    left: "-14%",
                    right: 0,
                  }}
                />
              );
            }
            return null;
          });
        }
      }

      if (line.index === 1) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div
                  key={`row-${index}-${diagIndex}`}
                  style={{
                    transform: "rotate(-45deg) translateX(50px)",
                    width: "45vw",
                    height: 5,
                    position: "absolute",
                    backgroundColor: "#FAFF00",
                    top: "73%",
                    left: "-15%",
                    right: 0,
                  }}
                />
              );
            }
            return null;
          });
        }
      }

      if (line.index === 2) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div
                  key={`row-${index}-${diagIndex}`}
                  style={{
                    transform: "rotate(-47deg) translateX(50px)",
                    width: "42vw",
                    height: 5,
                    position: "absolute",
                    backgroundColor: "#FAFF00",
                    top: "77%",
                    left: "28%",
                    right: 0,
                  }}
                />
              );
            }
            return null;
          });
        }
      }

      if (line.index === 3) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div
                  key={`row-${index}-${diagIndex}`}
                  style={{
                    transform: "rotate(47deg) translateX(50px)",
                    width: "45vw",
                    height: 5,
                    position: "absolute",
                    backgroundColor: "#FAFF00",
                    top: "25%",
                    left: "30%",
                    right: 0,
                  }}
                />
              );
            }
            return null;
          });
        }
      }
    }

    if (line.type === "diagonalTwo") {
      if (line.index === 0) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div key={`row-${index}-${diagIndex}`}>
                  <div
                    style={{
                      transform: "rotate(224deg) translateX(50px)",
                      width: "40vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "75%",
                      left: "16%",
                      right: 0,
                    }}
                  />

                  <div
                    style={{
                      transform: "rotate(-49deg) translateX(50px)",
                      width: "40vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "75%",
                      left: "31%",
                      right: 0,
                    }}
                  />
                </div>
              );
            }
            return null;
          });
        }
      }

      if (line.index === 1) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div key={`row-${index}-${diagIndex}`}>
                  <div
                    style={{
                      transform: "rotate(137deg) translateX(50px)",
                      width: "40vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "29%",
                      left: "16%",
                      right: 0,
                    }}
                  />

                  <div
                    style={{
                      transform: "rotate(45deg) translateX(50px)",
                      width: "40vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "30%",
                      left: "31%",
                      right: 0,
                    }}
                  />
                </div>
              );
            }
            return null;
          });
        }
      }
    }

    if (line.type === "diagonalTree") {
      if (line.index === 0) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div key={`row-${index}-${diagIndex}`}>
                  <div
                    style={{
                      transform: "rotate(133deg) translateX(50px)",
                      width: "23vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "12%",
                      left: "16%",
                      right: 0,
                    }}
                  />

                  <div
                    style={{
                      position: "absolute",
                      height: 5,
                      backgroundColor: "#FAFF00",
                      alignItems: "center",
                      top: "14%",
                      left: "30%",
                      width: "28vw",
                    }}
                  />

                  <div
                    style={{
                      transform: "rotate(43deg) translateX(50px)",
                      width: "23vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "12%",
                      left: "51%",
                      right: 0,
                    }}
                  />
                </div>
              );
            }
            return null;
          });
        }
      }

      if (line.index === 1) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div key={`row-${index}-${diagIndex}`}>
                  <div
                    style={{
                      transform: "rotate(-141deg) translateX(50px)",
                      width: "23vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "87%",
                      left: "16%",
                      right: 0,
                    }}
                  />

                  <div
                    style={{
                      position: "absolute",
                      height: 5,
                      backgroundColor: "#FAFF00",
                      alignItems: "center",
                      top: "85%",
                      left: "30%",
                      width: "28vw",
                    }}
                  />

                  <div
                    style={{
                      transform: "rotate(-41deg) translateX(50px)",
                      width: "23vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "87%",
                      left: "51%",
                      right: 0,
                    }}
                  />
                </div>
              );
            }
            return null;
          });
        }
      }
    }

    if (line.type === "diagonalFour") {
      if (line.index === 0) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div key={`row-${index}-${diagIndex}`}>
                  <div
                    style={{
                      width: "17vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "15%",
                      left: "7%",
                      right: 0,
                    }}
                  />

                  <div
                    style={{
                      transform: "rotate(47deg) translateX(50px)",
                      position: "absolute",
                      height: 5,
                      backgroundColor: "#FAFF00",
                      alignItems: "center",
                      top: "30%",
                      left: "10%",
                      width: "40vw",
                    }}
                  />

                  <div
                    style={{
                      width: "17vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "84%",
                      left: "69%",
                      right: 0,
                    }}
                  />
                </div>
              );
            }
            return null;
          });
        }
      }

      if (line.index === 1) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div key={`row-${index}-${diagIndex}`}>
                  <div
                    style={{
                      width: "17vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "85%",
                      left: "7%",
                      right: 0,
                    }}
                  />

                  <div
                    style={{
                      transform: "rotate(-44deg) translateX(50px)",
                      position: "absolute",
                      height: 5,
                      backgroundColor: "#FAFF00",
                      alignItems: "center",
                      top: "72%",
                      left: "10%",
                      width: "40vw",
                    }}
                  />

                  <div
                    style={{
                      width: "17vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "15%",
                      left: "69%",
                      right: 0,
                    }}
                  />
                </div>
              );
            }
            return null;
          });
        }
      }
    }

    if (line.type === "diagonalFive") {
      if (line.index === 0) {
        if (Array.isArray(gameResultDiagonal)) {
          return gameResultDiagonal.map((resDiagonal, diagIndex) => {
            if (
              line.id === resDiagonal.id &&
              line.index === resDiagonal.index
            ) {
              return (
                <div key={`row-${index}-${diagIndex}`}>
                  <div
                    style={{
                      transform: "rotate(44deg) translateX(50px)",
                      width: "22vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "47%",
                      left: "-8%",
                      right: 0,
                    }}
                  />

                  <div
                    style={{
                      transform: "rotate(-47deg) translateX(50px)",
                      position: "absolute",
                      height: 5,
                      backgroundColor: "#FAFF00",
                      alignItems: "center",
                      top: "76%",
                      left: "11%",
                      width: "40vw",
                    }}
                  />

                  <div
                    style={{
                      transform: "rotate(-133deg) translateX(50px)",
                      width: "22vw",
                      height: 5,
                      position: "absolute",
                      backgroundColor: "#FAFF00",
                      top: "56%",
                      left: "76%",
                      right: 0,
                    }}
                  />
                </div>
              );
            }
            return null;
          });
        }
      }
    }
  });
};
