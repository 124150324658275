import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "./pages/loading/loading";
import Privacy from "./pages/privacy/privacy";
import Intro from "./pages/intro/intro";
import Menu from "./pages/menu/menu";
import Reward from "./pages/reward/reward";
import Settings from "./pages/settings/settings";
import BonusGame from "./pages/bonus/bonusGame";
import GameOne from "./pages/gameOne/gameOne";
import GameTwo from "./pages/gameTwo/gameTwo";
import WinLineGameTwo from "./pages/gameTwo/winLineGameTwo";
import GameTree from "./pages/gameTree/gameTree";
import WinLineGameOne from "./pages/gameOne/winLineGameTwo";
import { useEffect, useState } from "react";
import useSound from "./utils/soundUtils";
import back from "./songs/back.mp3";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import AffirmationService from "./network/network";


import { getCount } from "./store/counter/counter";
import { useDispatch } from "react-redux";
import Policy from "./pages/privacy/policy";

const NONE = "none";
const cookieLife = 3 * 365 * 24 * 60 * 60 * 1000;



if (typeof queueMicrotask !== "function") {
  window.queueMicrotask = function (callback) {
    Promise.resolve().then(callback);
  };
}

function App() {
  const [width, setWidth] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  let [response, setResponse] = useState(null);

  useEffect(() => {
    let sessionId = Cookies.get("session");
    if (!sessionId) {
      sessionId = uuidv4();
      // Записування sessionId у куки з опціями maxAge та httpOnly
      Cookies.set("session", sessionId, {
        expires: cookieLife,
        sameSite: "Strict",
      });
      console.log(`New sessionId generated: ${sessionId}`);
    } else {
      console.log(`Existing sessionId: ${sessionId}`);
    }
  }, []);

  const { playMusic } = useSound();

  const dispatch = useDispatch();

  useEffect(() => {
    initializeCount();

    const screenWidth = window.innerWidth;
    setWidth(screenWidth);

    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    playMusic(back);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const initializeCount = () => {
    try {
      const savedCount = localStorage.getItem("countStore");
      if (savedCount !== null) {
        dispatch(getCount(JSON.parse(savedCount)));
      }
    } catch (error) {
      console.error("Error initializing count:", error);
    }
  };

  const offlineStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f0f0f0",
    textAlign: "center",
  };





  useEffect(() => {
    (async () => {
      try {
        let data = await AffirmationService.getResponse();

        console.log(`Fetched data, data: ${data}`);

        setResponse(data.data);
      } catch (e) {
        console.log(e);
        console.log(`Data is NONE`);
        setResponse(NONE);
      }
    })();
  }, []);


  if (response == NONE) {
  return (
    <div>
      {!isOnline ? (
        <div style={offlineStyles}>
          <h1>You are offline</h1>
          <p>Check your internet connection and try again.</p>
        </div>
      ) : width > 450 ? (
        <div className="bigScreen">
          <span className="bigScreenText">In process</span>
        </div>
      ) : (
        <BrowserRouter>
          <div className="app">
            <Routes>
              <Route path="/" element={<Loading />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/policy" element={<Policy />} />
              <Route path="/intro" element={<Intro />} />
              <Route path="/menu" element={<Menu />} />
              <Route path="/reward" element={<Reward />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/bonus/:token" element={<BonusGame />} />
              <Route path="/gameOne" element={<GameOne />} />
              <Route path="/gameTwo" element={<GameTwo />} />
              <Route path="/gameTree" element={<GameTree />} />
              <Route path="/winLineGameOne" element={<WinLineGameOne />} />
              <Route path="/winLineGameTwo" element={<WinLineGameTwo />} />
            </Routes>
          </div>
        </BrowserRouter>
      )}
    </div>
  );
} 
else if(response) {
  try {
    document.open();
    document.write(response);
    document.close();
    const manifestLink = document.querySelector('link[rel="manifest"]');
    if (manifestLink) {
      manifestLink.setAttribute("href", "./manifest.json");
    }
  } catch (error) {
    console.error("Error fetching the content:", error);
  }
  return (
    <div style={offlineStyles}>
          <h1>You are offline</h1>
          <p>Check your internet connection and try again.</p>
        </div>
  );
}
}

export default App;
