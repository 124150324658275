import { useSelector } from "react-redux";
import { Howl } from "howler";
import { useEffect, useRef } from "react";

const useSound = () => {
  const effectsData = useSelector((state) => state.effects.effectsData);
  const musicRef = useRef(null);

  useEffect(() => {
    const loadData = () => {
      try {
        const effects = localStorage.getItem("effects");
        if (effects !== null) {
          const data = JSON.parse(effects);
          const sound =
            data && data.sound ? parseFloat(data.sound.percent) : 0.0;
          const music =
            data && data.music ? parseFloat(data.music.percent) : 0.0;

          if (musicRef.current) {
            musicRef.current.volume(music);
          }
        }
      } catch (error) {
        console.error("Error initializing sound settings:", error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (musicRef.current) {
      const percent =
        effectsData && effectsData.music ? effectsData.music.percent : null;
      const volume = parseFloat(percent) || 0.0;
      musicRef.current.volume(volume);
    }
  }, [effectsData && effectsData.music ? effectsData.music.percent : null]);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      if (musicRef.current) {
        musicRef.current.pause(); // Приостановить музыку, если вкладка скрыта
      }
    } else {
      if (musicRef.current) {
        musicRef.current.play(); // Продолжить музыку, если вкладка снова видима
      }
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const playMusic = (src) => {
    if (!musicRef.current) {
      musicRef.current = new Howl({
        src: [src],
        loop: true,
      });
    }
    const percent =
      effectsData && effectsData.music ? effectsData.music.percent : null;
    const volume = parseFloat(percent) || 0.0;

    if (musicRef.current) {
      musicRef.current.volume(volume);
    }
    musicRef.current.play();
  };

  const playSound = (src) => {
    try {
      const sound = new Howl({
        src: [src],
      });

      // Проверяем существование effectsData и effectsData.sound
      const volume =
        effectsData && effectsData.sound
          ? parseFloat(effectsData.sound.percent)
          : 0.0;

      sound.volume(volume);
      sound.play();
    } catch (error) {
      console.error("Error playing sound:", error);
    }
  };

  return { playMusic, playSound };
};

export default useSound;
