import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import style from "./slotMachine.module.css";
import { RenderLines } from "./SlotMachineLines";

import win from "../../songs/win.mp3";
import lose from "../../songs/lose.mp3";
import useSound from "../../utils/soundUtils";

const frames = [
  {
    img: "/assets/cherry.png",
    imgBlack: "/assets/cherryBlack.png",
    alt: "cherry",
    id: 1,
  },
  {
    img: "/assets/orange.png",
    imgBlack: "/assets/orangeBlack.png",
    alt: "orange",
    id: 2,
  },
  {
    img: "/assets/lemon.png",
    imgBlack: "/assets/lemonBlack.png",
    alt: "lemon",
    id: 3,
  },

  {
    img: "/assets/grapes.png",
    imgBlack: "/assets/grapesBlack.png",
    alt: "grapes",
    id: 4,
  },

  {
    img: "/assets/bells.png",
    imgBlack: "/assets/bellsBlack.png",
    alt: "bells",
    id: 5,
  },

  {
    img: "/assets/seven.png",
    imgBlack: "/assets/sevenBlack.png",
    alt: "seven",
    id: 6,
  },
];

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const SlotMachine = ({
  startSpin,
  setStartSpin,
  bet,
  setWinCounter,
  setFreezeButton,
}) => {
  const [slots, setSlots] = useState([[], [], [], [], []]);
  const [spinKeys, setSpinKeys] = useState([0, 1, 2, 3, 4]);
  const [activeSlot, setActiveSlot] = useState(-1);

  const [countResultLine, setCountResultLine] = useState([]);
  const [countResultDiagonal, setCountResultDiagonal] = useState([]);

  const [gameResultRow, setGameResultRow] = useState(null);
  const [gameResultDiagonal, setGameResultDiagonal] = useState(null);

  const [hideLine, setHideLine] = useState(false);

  const [lines, setLines] = useState([]);

  const [totalWinRow, setTotalWinRow] = useState(0);
  const [totalWinDiagonal, setTotalWinDiagonal] = useState(0);

  const increaseWinProbability = 0.2;

  const { playSound } = useSound();

  useEffect(() => {
    resetGame();
  }, []);

  const resetGame = () => {
    const newSlots = [];
    for (let i = 0; i < 5; i++) {
      const clonedFrames = frames.slice();

      // Перемешиваем клонированный массив
      const shuffledFrames = shuffleArray(clonedFrames);

      // Обрезаем массив до 3 элементов
      const slicedFrames = shuffledFrames.slice(0, 5);

      // Добавляем в newSlots
      newSlots.push(slicedFrames);

      // newSlots.push(shuffleArray([...frames]).slice(0, 5));
    }
    setSlots(newSlots);
  };

  const checkWin = (result) => {
    const { slot1, slot2, slot3, slot4, slot5 } = result;
    let win = false;
    const newLines = [];

    let countLine = [];
    let countDiagonal = [];

    let rows = [];
    let diagonal = [];

    // Сheck rows 3

    for (let i = 0; i < 3; i++) {
      if (
        slot1[i].id === slot2[i].id &&
        slot2[i].id === slot3[i].id
        // &&
        // slot3[i].id !== slot4[i].id &&
        // slot4[i].id !== slot5[i].id
      ) {
        let res = {
          slot1: {
            id: slot1[i].id,
            index: i,
          },
          slot2: {
            id: slot2[i].id,
            index: i,
          },
          slot3: {
            id: slot3[i].id,
            index: i,
          },
        };

        let dataSlot = {
          id: slot1[i].id,
          count: 3,
          index: i,
        };

        countLine.push(res);
        rows.push(dataSlot);

        win = true;

        newLines.push({ type: "row", index: i, slot: "slot1" });
      }
    }

    for (let i = 0; i < 3; i++) {
      if (
        // slot1[i].id !== slot2[i].id &&
        // slot2[i].id !== slot3[i].id &&
        slot3[i].id === slot4[i].id &&
        slot4[i].id === slot5[i].id
      ) {
        let res = {
          slot3: {
            id: slot3[i].id,
            index: i,
          },
          slot4: {
            id: slot4[i].id,
            index: i,
          },
          slot5: {
            id: slot5[i].id,
            index: i,
          },
        };

        let dataSlot = {
          id: slot3[i].id,
          count: 3,
          index: i,
        };

        countLine.push(res);
        rows.push(dataSlot);

        win = true;

        newLines.push({ type: "row", index: i, slot: "slot3" });
      }
    }
    // Check rows 4

    for (let i = 0; i < 3; i++) {
      if (
        slot1[i].id === slot2[i].id &&
        slot2[i].id == slot3[i].id &&
        slot3[i].id === slot4[i].id
        // &&
        // slot4[i].id !== slot5[i].id
      ) {
        let res = {
          slot1: {
            id: slot1[i].id,
            index: i,
          },
          slot2: {
            id: slot2[i].id,
            index: i,
          },
          slot3: {
            id: slot3[i].id,
            index: i,
          },
          slot4: {
            id: slot4[i].id,
            index: i,
          },
        };

        let dataSlot = {
          id: slot1[i].id,
          count: 4,
          index: i,
        };

        countLine.push(res);
        rows.push(dataSlot);

        win = true;

        newLines.push({ type: "row", index: i, slot: "slot1" });
      }
    }

    for (let i = 0; i < 3; i++) {
      if (
        // slot1[i].id !== slot2[i].id &&
        slot2[i].id === slot3[i].id &&
        slot3[i].id === slot4[i].id &&
        slot4[i].id === slot5[i].id
      ) {
        let res = {
          slot2: {
            id: slot2[i].id,
            index: i,
          },
          slot3: {
            id: slot3[i].id,
            index: i,
          },
          slot4: {
            id: slot4[i].id,
            index: i,
          },
          slot5: {
            id: slot5[i].id,
            index: i,
          },
        };

        let dataSlot = {
          id: slot2[i].id,
          count: 4,
          index: i,
        };

        countLine.push(res);
        rows.push(dataSlot);

        win = true;

        newLines.push({ type: "row", index: i, slot: "slot3" });
      }
    }

    // Check rows 5
    for (let i = 0; i < 3; i++) {
      if (
        slot1[i].id === slot2[i].id &&
        slot2[i].id === slot3[i].id &&
        slot3[i].id === slot4[i].id &&
        slot4[i].id === slot5[i].id
      ) {
        let res = {
          slot1: {
            id: slot1[i].id,
            index: i,
          },
          slot2: {
            id: slot2[i].id,
            index: i,
          },
          slot3: {
            id: slot3[i].id,
            index: i,
          },
          slot4: {
            id: slot4[i].id,
            index: i,
          },
          slot5: {
            id: slot5[i].id,
            index: i,
          },
        };

        let dataSlot = {
          id: slot1[i].id,
          count: 5,
          index: i,
        };

        countLine.push(res);
        rows.push(dataSlot);

        win = true;

        newLines.push({ type: "row", index: i, slot: "slot1" });
      }
    }

    setCountResultLine(countLine);

    // Check diagonals 3

    if (slot1[0].id === slot2[1].id && slot2[1].id === slot3[2].id) {
      win = true;

      newLines.push({ type: "diagonal", index: 0, id: slot1[0].id });

      let res = {
        slot1: {
          id: slot1[0].id,
          index: 0,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[2].id,
          index: 2,
        },
      };
      let dataSlot = {
        id: slot1[0].id,
        count: 3,
        index: 0,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (slot1[2].id === slot2[1].id && slot2[1].id === slot3[0].id) {
      win = true;

      newLines.push({ type: "diagonal", index: 1, id: slot1[2].id });

      let res = {
        slot1: {
          id: slot1[2].id,
          index: 2,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[0].id,
          index: 0,
        },
      };
      let dataSlot = {
        id: slot1[2].id,
        count: 3,
        index: 1,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (slot3[2].id === slot4[1].id && slot4[1].id === slot5[0].id) {
      win = true;

      newLines.push({ type: "diagonal", index: 2, id: slot3[2].id });

      let res = {
        slot3: {
          id: slot3[2].id,
          index: 2,
        },
        slot4: {
          id: slot4[1].id,
          index: 1,
        },
        slot5: {
          id: slot5[0].id,
          index: 0,
        },
      };
      let dataSlot = {
        id: slot3[2].id,
        count: 3,
        index: 2,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (slot3[0].id === slot4[1].id && slot4[1].id === slot5[2].id) {
      win = true;

      newLines.push({ type: "diagonal", index: 3, id: slot3[0].id });

      let res = {
        slot3: {
          id: slot3[0].id,
          index: 0,
        },
        slot4: {
          id: slot4[1].id,
          index: 1,
        },
        slot5: {
          id: slot5[2].id,
          index: 2,
        },
      };
      let dataSlot = {
        id: slot3[0].id,
        count: 3,
        index: 3,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    // Check diagonals 5

    if (
      slot1[0].id === slot2[1].id &&
      slot2[1].id === slot3[2].id &&
      slot3[2].id === slot4[1].id &&
      slot4[1].id === slot5[0].id
    ) {
      win = true;

      newLines.push({ type: "diagonal", index: 0, id: slot1[0].id });

      let res = {
        slot1: {
          id: slot1[0].id,
          index: 0,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[2].id,
          index: 2,
        },
        slot4: {
          id: slot4[1].id,
          index: 1,
        },
        slot5: {
          id: slot5[0].id,
          index: 0,
        },
      };

      let dataSlot = {
        id: slot1[0].id,
        count: 5,
        index: 0,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (
      slot1[2].id === slot2[1].id &&
      slot2[1].id === slot3[0].id &&
      slot3[0].id === slot4[1].id &&
      slot4[1].id === slot5[2].id
    ) {
      win = true;

      newLines.push({ type: "diagonal", index: 1, id: slot1[2].id });

      let res = {
        slot1: {
          id: slot1[2].id,
          index: 2,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[0].id,
          index: 0,
        },
        slot4: {
          id: slot4[1].id,
          index: 1,
        },
        slot5: {
          id: slot5[2].id,
          index: 2,
        },
      };

      let dataSlot = {
        id: slot1[2].id,
        count: 5,
        index: 1,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (
      slot1[0].id === slot2[1].id &&
      slot2[1].id === slot3[2].id &&
      slot3[2].id === slot4[1].id &&
      slot4[1].id === slot5[0].id
    ) {
      win = true;

      newLines.push({ type: "diagonalTwo", index: 0, id: slot1[0].id });

      let res = {
        slot1: {
          id: slot1[0].id,
          index: 0,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[2].id,
          index: 2,
        },
        slot4: {
          id: slot4[1].id,
          index: 1,
        },
        slot5: {
          id: slot5[0].id,
          index: 0,
        },
      };

      let dataSlot = {
        id: slot1[0].id,
        count: 5,
        index: 0,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (
      slot1[2].id === slot2[1].id &&
      slot2[1].id === slot3[0].id &&
      slot3[0].id === slot4[1].id &&
      slot4[1].id === slot5[2].id
    ) {
      win = true;

      newLines.push({ type: "diagonalTwo", index: 1, id: slot1[2].id });

      let res = {
        slot1: {
          id: slot1[2].id,
          index: 2,
        },
        slot2: {
          id: slot2[1].id,
          index: 1,
        },
        slot3: {
          id: slot3[0].id,
          index: 0,
        },
        slot4: {
          id: slot4[1].id,
          index: 1,
        },
        slot5: {
          id: slot5[2].id,
          index: 2,
        },
      };

      let dataSlot = {
        id: slot1[2].id,
        count: 5,
        index: 1,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (
      slot1[1].id === slot2[0].id &&
      slot2[0].id === slot3[0].id &&
      slot3[0].id === slot4[0].id &&
      slot4[0].id === slot5[1].id
    ) {
      win = true;

      newLines.push({ type: "diagonalTree", index: 0, id: slot1[1].id });

      let res = {
        slot1: {
          id: slot1[1].id,
          index: 1,
        },
        slot2: {
          id: slot2[0].id,
          index: 0,
        },
        slot3: {
          id: slot3[0].id,
          index: 0,
        },
        slot4: {
          id: slot4[0].id,
          index: 0,
        },
        slot5: {
          id: slot5[1].id,
          index: 1,
        },
      };

      let dataSlot = {
        id: slot1[1].id,
        count: 5,
        index: 0,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (
      slot1[1].id === slot2[2].id &&
      slot2[2].id === slot3[2].id &&
      slot3[2].id === slot4[2].id &&
      slot4[2].id === slot5[1].id
    ) {
      win = true;

      newLines.push({ type: "diagonalTree", index: 1, id: slot1[1].id });

      let res = {
        slot1: {
          id: slot1[1].id,
          index: 1,
        },
        slot2: {
          id: slot2[2].id,
          index: 2,
        },
        slot3: {
          id: slot3[2].id,
          index: 2,
        },
        slot4: {
          id: slot4[2].id,
          index: 2,
        },
        slot5: {
          id: slot5[1].id,
          index: 1,
        },
      };

      let dataSlot = {
        id: slot1[1].id,
        count: 5,
        index: 1,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (
      slot1[0].id === slot2[0].id &&
      slot2[0].id === slot3[1].id &&
      slot3[1].id === slot4[2].id &&
      slot4[2].id === slot5[2].id
    ) {
      win = true;

      newLines.push({ type: "diagonalFour", index: 0, id: slot1[0].id });

      let res = {
        slot1: {
          id: slot1[0].id,
          index: 0,
        },
        slot2: {
          id: slot2[0].id,
          index: 0,
        },
        slot3: {
          id: slot3[1].id,
          index: 1,
        },
        slot4: {
          id: slot4[2].id,
          index: 2,
        },
        slot5: {
          id: slot5[2].id,
          index: 2,
        },
      };

      let dataSlot = {
        id: slot1[0].id,
        count: 5,
        index: 0,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (
      slot1[2].id === slot2[2].id &&
      slot2[2].id === slot3[1].id &&
      slot3[1].id === slot4[0].id &&
      slot4[0].id === slot5[0].id
    ) {
      win = true;

      newLines.push({ type: "diagonalFour", index: 1, id: slot1[2].id });

      let res = {
        slot1: {
          id: slot1[2].id,
          index: 2,
        },
        slot2: {
          id: slot2[2].id,
          index: 2,
        },
        slot3: {
          id: slot3[1].id,
          index: 1,
        },
        slot4: {
          id: slot4[0].id,
          index: 0,
        },
        slot5: {
          id: slot5[0].id,
          index: 0,
        },
      };

      let dataSlot = {
        id: slot1[2].id,
        count: 5,
        index: 1,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    if (
      slot1[1].id === slot2[2].id &&
      slot2[2].id === slot3[1].id &&
      slot3[1].id === slot4[0].id &&
      slot4[0].id === slot5[1].id
    ) {
      win = true;

      newLines.push({ type: "diagonalFive", index: 0, id: slot1[1].id });

      let res = {
        slot1: {
          id: slot1[1].id,
          index: 1,
        },
        slot2: {
          id: slot2[2].id,
          index: 2,
        },
        slot3: {
          id: slot3[1].id,
          index: 1,
        },
        slot4: {
          id: slot4[0].id,
          index: 0,
        },
        slot5: {
          id: slot5[1].id,
          index: 1,
        },
      };

      let dataSlot = {
        id: slot1[1].id,
        count: 5,
        index: 0,
      };

      diagonal.push(dataSlot);
      countDiagonal.push(res);
    }

    setGameResultRow(rows);

    setGameResultDiagonal(diagonal);

    setCountResultDiagonal(countDiagonal);

    setLines(newLines);
    return win;
  };

  useEffect(() => {
    const calculateWin = (gameResult) => {
      let res = [];
      if (Array.isArray(gameResult)) {
        gameResult.forEach((item) => {
          let winPercentage = 0;

          switch (item.id) {
            case 1:
              winPercentage =
                item && item.count === 3
                  ? 1.0
                  : item && item.count === 4
                  ? 1.2
                  : item && item.count === 5
                  ? 1.4
                  : 1.0;
              break;
            case 2:
              winPercentage =
                item && item.count === 3
                  ? 1.2
                  : item && item.count === 4
                  ? 1.4
                  : item && item.count === 5
                  ? 1.8
                  : 1.2;
              break;
            case 3:
              winPercentage =
                item && item.count === 3
                  ? 1.4
                  : item && item.count === 4
                  ? 1.6
                  : item && item.count === 5
                  ? 1.8
                  : 1.4;
              break;
            case 4:
              winPercentage =
                item && item.count === 3
                  ? 1.6
                  : item && item.count === 4
                  ? 1.8
                  : item && item.count === 5
                  ? 2.0
                  : 1.6;
              break;
            case 5:
              winPercentage =
                item && item.count === 3
                  ? 1.8
                  : item && item.count === 4
                  ? 2.0
                  : item && item.count === 5
                  ? 3.0
                  : 1.8;
              break;
            case 6:
              winPercentage =
                item && item.count === 3
                  ? 2.0
                  : item && item.count === 4
                  ? 2.5
                  : item && item.count === 5
                  ? 5.0
                  : 2.0;
              break;
            default:
              winPercentage = 0;
              break;
          }

          const winAmount = bet * winPercentage;
          res.push(winAmount);
        });

        const sumNumbers = res.reduce((a, b) => a + b, 0);
        return sumNumbers;
      }
    };

    if (gameResultRow !== undefined && gameResultRow !== null) {
      const totalWinRow = calculateWin(gameResultRow);
      setTotalWinRow(totalWinRow);
    }
  }, [gameResultRow]);

  useEffect(() => {
    const calculateWin = (gameResult) => {
      let res = [];
      if (Array.isArray(gameResult)) {
        gameResult.forEach((item) => {
          let winPercentage = 0;

          switch (item.id) {
            case 1:
              winPercentage =
                item && item.count === 3
                  ? 1.0
                  : item && item.count === 4
                  ? 1.2
                  : item && item.count === 5
                  ? 1.4
                  : 1.0;
              break;
            case 2:
              winPercentage =
                item && item.count === 3
                  ? 1.2
                  : item && item.count === 4
                  ? 1.4
                  : item && item.count === 5
                  ? 1.8
                  : 1.2;
              break;
            case 3:
              winPercentage =
                item && item.count === 3
                  ? 1.4
                  : item && item.count === 4
                  ? 1.6
                  : item && item.count === 5
                  ? 1.8
                  : 1.4;
              break;
            case 4:
              winPercentage =
                item && item.count === 3
                  ? 1.6
                  : item && item.count === 4
                  ? 1.8
                  : item && item.count === 5
                  ? 2.0
                  : 1.6;
              break;
            case 5:
              winPercentage =
                item && item.count === 3
                  ? 1.8
                  : item && item.count === 4
                  ? 2.0
                  : item && item.count === 5
                  ? 3.0
                  : 1.8;
              break;
            case 6:
              winPercentage =
                item && item.count === 3
                  ? 2.0
                  : item && item.count === 4
                  ? 2.5
                  : item && item.count === 5
                  ? 5.0
                  : 2.0;
              break;
            default:
              winPercentage = 0;
              break;
          }

          const winAmount = bet * winPercentage;
          res.push(winAmount);
        });

        const sumNumbers = res.reduce((a, b) => a + b, 0);
        return sumNumbers;
      }
    };

    if (gameResultDiagonal !== undefined && gameResultDiagonal !== null) {
      const totalWinDiagonal = calculateWin(gameResultDiagonal);
      setTotalWinDiagonal(totalWinDiagonal);
    }
  }, [gameResultDiagonal]);

  useEffect(() => {
    // Логика вывода результатов в зависимости от наличия данных
    if (totalWinRow !== 0 || totalWinDiagonal !== 0) {
      let result = [];

      if (totalWinRow !== 0) {
        console.log("totalWinRow", totalWinRow);

        result.push(totalWinRow);
      }
      if (totalWinDiagonal !== 0) {
        console.log("totalWinDiagonal", totalWinDiagonal);

        result.push(totalWinDiagonal);
      }

      const sumNumbers = result.reduce((a, b) => a + b, 0);

      setTimeout(() => {
        setWinCounter(sumNumbers);
      }, 1000);

      setTotalWinDiagonal(0);
      setTotalWinRow(0);
    }
  }, [totalWinRow, totalWinDiagonal]);

  const handleSpin = () => {
    setCountResultLine([]);
    setCountResultDiagonal([]);

    setHideLine(true);

    const newSlots = [];

    // newSlots.push(shuffleArray([...frames]).slice(0, 3));

    const clonedFrames = frames.slice();

    // Перемешиваем клонированный массив
    const shuffledFrames = shuffleArray(clonedFrames);

    // Обрезаем массив до 3 элементов
    const slicedFrames = shuffledFrames.slice(0, 3);

    // Добавляем в newSlots
    newSlots.push(slicedFrames);

    for (let i = 1; i < 5; i++) {
      let currentSlot;

      // С вероятностью increaseWinProbability делаем слоты совпадающими с предыдущими
      // if (Math.random() < increaseWinProbability) {
      //   currentSlot = [...newSlots[i - 1]]; // Копируем предыдущий слот
      //   if (Math.random() < increaseWinProbability) {
      //     // Случайно заменяем одно изображение в слоте
      //     currentSlot[Math.floor(Math.random() * 3)] = shuffleArray([
      //       ...frames,
      //     ])[0];
      //   }
      // } else {
      //   currentSlot = shuffleArray([...frames]).slice(0, 3); // Случайное заполнение
      // }

      if (Math.random() < increaseWinProbability) {
        // Клонируем предыдущий слот
        currentSlot = newSlots[i - 1].slice();

        if (Math.random() < increaseWinProbability) {
          // Случайно заменяем одно изображение в слоте
          const randomIndex = Math.floor(Math.random() * 3);
          const shuffledFrames = shuffleArray(frames.slice());
          currentSlot[randomIndex] = shuffledFrames[0];
        }
      } else {
        // Случайное заполнение
        const shuffledFrames = shuffleArray(frames.slice());
        currentSlot = shuffledFrames.slice(0, 3);
      }

      newSlots.push(currentSlot);
    }

    setSpinKeys((prevKeys) => prevKeys.map((key) => key + 1));

    setTimeout(() => {
      setSlots(newSlots);
    }, 1000);

    const result = {
      slot1: newSlots[0],
      slot2: newSlots[1],
      slot3: newSlots[2],
      slot4: newSlots[3],
      slot5: newSlots[4],
    };

    setTimeout(() => {
      if (checkWin(result)) {
        playSound(win);
        setFreezeButton(false);
      } else {
        playSound(lose);
        setFreezeButton(false);
      }
      setStartSpin(false);
      setHideLine(false);
    }, 2100);

    setActiveSlot(0);
  };

  useEffect(() => {
    if (startSpin) {
      handleSpin();
    }
  }, [startSpin]);

  useEffect(() => {
    if (activeSlot >= 0 && activeSlot < slots.length) {
      const timer = setTimeout(() => {
        setActiveSlot((prev) => prev + 1);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [activeSlot]);

  const renderSlot = (slotIndex, slot) => {
    const animatedImages = Array.from(
      { length: 30 },
      (_, index) => slots[slotIndex][index % slots[slotIndex].length]
    );

    const getImageStyle = (slotIndex, indexInSlot) => {
      let showBack = false;

      // Проверяем все линии
      countResultLine.length > 0 &&
        countResultLine.forEach((countLine) => {
          switch (slotIndex) {
            case 0:
              if (countLine.slot1 && countLine.slot1.index === indexInSlot) {
                showBack = true;
              }
              break;
            case 1:
              if (countLine.slot2 && countLine.slot2.index === indexInSlot) {
                showBack = true;
              }
              break;
            case 2:
              if (countLine.slot3 && countLine.slot3.index === indexInSlot) {
                showBack = true;
              }
              break;
            case 3:
              if (countLine.slot4 && countLine.slot4.index === indexInSlot) {
                showBack = true;
              }
              break;

            case 4:
              if (countLine.slot5 && countLine.slot5.index === indexInSlot) {
                showBack = true;
              }
              break;
            default:
              break;
          }
        });

      // Проверяем диагональные линии
      if (countResultDiagonal) {
        countResultDiagonal.length > 0 &&
          countResultDiagonal?.forEach((countDiagonal) => {
            switch (slotIndex) {
              case 0:
                if (
                  countDiagonal.slot1 &&
                  countDiagonal.slot1.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              case 1:
                if (
                  countDiagonal.slot2 &&
                  countDiagonal.slot2.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              case 2:
                if (
                  countDiagonal.slot3 &&
                  countDiagonal.slot3.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              case 3:
                if (
                  countDiagonal.slot4 &&
                  countDiagonal.slot4.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              case 4:
                if (
                  countDiagonal.slot5 &&
                  countDiagonal.slot5.index === indexInSlot
                ) {
                  showBack = true;
                }
                break;
              default:
                break;
            }
          });
      }

      return { showBack };
    };

    const vh = window.innerHeight / 100;
    const animationHeight = -84 * vh;

    return (
      <div className={style.slotBlock} key={slotIndex}>
        <motion.div
          key={spinKeys[slotIndex]}
          className={style.slotsInner}
          initial={{ y: animationHeight }}
          animate={{ y: activeSlot >= slotIndex ? 0 : animationHeight }}
          transition={{ duration: 1, ease: "linear" }}
        >
          {animatedImages.map((image, index) => {
            const { showBack } = getImageStyle(slotIndex, index % slot.length);
            return (
              <div className={style.slotsItem} key={index}>
                {showBack ? (
                  <>
                    <img
                      src={image && image.img}
                      alt={image && image.alt}
                      className={style.slotsItemImage}
                    />
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      style={{
                        position: "absolute",
                        top: 6,
                        left: 0,
                        right: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: -10,
                      }}
                    >
                      <img
                        src="/assets/lightGameTwo.png"
                        alt=""
                        className={style.lightSlotsImage}
                      />
                    </motion.div>
                  </>
                ) : countResultLine.length > 0 ||
                  countResultDiagonal.length > 0 ? (
                  <>
                    <img
                      src={image && image.imgBlack}
                      alt={image && image.alt}
                      className={style.slotsItemImage}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={image && image.img}
                      alt={image && image.alt}
                      className={style.slotsItemImage}
                    />
                  </>
                )}
              </div>
            );
          })}
        </motion.div>
      </div>
    );
  };

  return (
    <div className={style.slotsMachine}>
      {!hideLine && (
        <RenderLines
          lines={lines}
          gameResultRow={gameResultRow}
          gameResultDiagonal={gameResultDiagonal}
        />
      )}
      {slots.map((slot, index) => renderSlot(index, slot))}
    </div>
  );
};

export default SlotMachine;
