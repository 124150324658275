import React, { useEffect, useState } from "react";
import style from "./loading.module.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import moment from "moment";

export default function Loading() {
  const [giftTakenToday, setGiftTakenToday] = useState(false);
  const [completedDaily, setCompletedDaily] = useState(false);
  const [visit, setVisit] = useState(0);
  const [privacy, setPrivacy] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    checkGiftTakenToday();
  }, []);

  const checkGiftTakenToday = () => {
    const privacyData = localStorage.getItem("privacy");
    const parsedPrivacy = JSON.parse(privacyData);

    setPrivacy(!!parsedPrivacy);

    try {
      const today = moment().format("YYYY-MM-DD");
      const lastVisitString = localStorage.getItem("lastVisit");
      const lastVisit = lastVisitString
        ? moment(lastVisitString).format("YYYY-MM-DD")
        : null;
      const data = localStorage.getItem("completed");
      const completed = JSON.parse(data);

      let visitCount = parseInt(localStorage.getItem("visitCount"), 10);

      if (isNaN(visitCount)) {
        visitCount = 0;
      }

      setVisit(visitCount);

      if (lastVisit) {
        const daysDifference = moment(today).diff(moment(lastVisit), "days");
        console.log("Days difference:", daysDifference);

        if (daysDifference === 0) {
          setCompletedDaily(completed === true);
          setGiftTakenToday(completed === true);
        } else {
          setGiftTakenToday(false);
          setCompletedDaily(false);
          localStorage.setItem("completed", JSON.stringify(false));
        }
      } else {
        localStorage.setItem("completed", JSON.stringify(false));
      }
    } catch (error) {
      console.error("Error checking if gift was taken today:", error);
    }
  };

  const navigateToScreen = () => {
    if (privacy) {
      setTimeout(() => {
        navigate(
          giftTakenToday || completedDaily || visit >= 7 ? "/intro" : "/reward"
        );
      }, 2000);
    } else {
      setTimeout(() => {
        navigate("/privacy");
      }, 2000);
    }
  };

  useEffect(() => {
    navigateToScreen();
  }, [privacy, giftTakenToday, completedDaily, visit]);

  return (
    <div className={style.loading}>
      <div className={style.loadingBlock}>
        <div>
          <p className={style.loadingText}>Loading...</p>
        </div>
        <div className={style.animateBlock}>
          <motion.div
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 3 }} // Длительность анимации
            style={{
              height: "3vh",
              width: "100%",
              backgroundColor: "#b2275d",
              originX: 0, // Устанавливаем начальную точку расширения слева
              transformOrigin: "left", // Анимация будет растягиваться от левого края
              transform: "scaleX(0)", // Начальное состояние масштабирования по оси X
              borderRadius: "3vw",
            }}
          ></motion.div>
        </div>
      </div>
    </div>
  );
}
