import React, { useEffect, useState } from "react";
import style from "./gameTree.module.css";
import SlotMachineGameTree from "./slotMachineGameTree";
import { useDispatch, useSelector } from "react-redux";
import { decrement, increment } from "../../store/counter/counter";
import backSVG from "../../svg/back.svg";

import bets from "../../songs/bet.mp3";
import slot from "../../songs/slot.mp3";
import press from "../../songs/onpress.mp3";
import useSound from "../../utils/soundUtils";
import { useNavigate } from "react-router-dom";

import CryptoJS from "crypto-js";

export default function GameTree() {
  const [openPopup, setOpenPopup] = useState(false);
  const [startSpin, setStartSpin] = useState(false);

  const [freezeButton, setFreezeButton] = useState(false);

  const [winCount, setWinCounter] = useState(0);

  const [bonusGame, setBonusGame] = useState(0);

  const [bet, setBet] = useState(50);

  const [chosenWild, setChosenWild] = useState("");

  const { playSound } = useSound();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const counter = useSelector((state) => state.counter.count);

  const openWild = () => {
    playSound(press);
    const timer = setTimeout(() => {
      setOpenPopup(true);
    }, 500);

    return () => clearTimeout(timer);
  };

  const closeWild = () => {
    playSound(press);
    const timer = setTimeout(() => {
      setOpenPopup(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  const plusBet = () => {
    playSound(bets);

    setBet((prev) => {
      const newBet =
        prev < counter ? (prev + 50 < counter ? prev + 50 : counter) : counter;
      return newBet;
    });
  };

  const minusBet = () => {
    playSound(bets);

    setBet((prev) => {
      const newBet = prev - 50;

      if (newBet < 0) {
        return 0;
      }

      return newBet;
    });
  };

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const generateToken = (page, bet) => {
    const data = { page, bet };
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey
    ).toString();
    return encodeURIComponent(encryptedData);
  };

  useEffect(() => {
    if (bonusGame > 0 && bonusGame % 15 === 0) {
      const page = "gameTree";
      const token = generateToken(page, bet);
      navigate(`/bonus/${token}`);
    }
  }, [bonusGame]);

  const goSpin = () => {
    if (bet > 0 && counter >= bet && !freezeButton) {
      setBonusGame(bonusGame + 1);

      if ((bonusGame + 1) % 15 === 0) {
        setStartSpin(false);
        setFreezeButton(false);
      } else {
        setFreezeButton(true);
        playSound(slot);
        dispatch(decrement(bet));
        setStartSpin(true);
      }
    }
  };

  useEffect(() => {
    if (winCount > 0) {
      setTimeout(() => {
        dispatch(increment(winCount));
      }, 1500);
      setTimeout(() => {
        setWinCounter(0);
      }, 2000);
    }
  }, [winCount]);

  const choiceWild = (wild) => {
    playSound(press);

    setChosenWild(wild);

    const timer = setTimeout(() => {
      setOpenPopup(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  const backPage = () => {
    navigate("/menu");
  };

  return (
    <div className={style.gameTree}>
      <div className={style.blockBack}>
        <img
          src={backSVG}
          alt=""
          onClick={backPage}
          className={style.imageBack}
        />
      </div>
      {openPopup && (
        <div className={style.popupSelectWildBlock}>
          <div className={style.popupSelectWild}>
            <img
              src="/assets/frameSelectWild.png"
              alt=""
              className={style.imageFrameSelectWild}
            />
            <div className={style.ChoiseBlock}>
              <div className={style.buttonPopup}>
                <img
                  src="/assets/buttonChoiseGameTree.png"
                  alt=""
                  className={style.imageButtonChoise}
                  onClick={closeWild}
                />
              </div>
              <div className={style.selectWildOne}>
                <img
                  src="/assets/wildx6.png"
                  alt=""
                  className={style.imageWildx}
                  onClick={() => choiceWild("x6")}
                />
                <img
                  src="/assets/wildx1.png"
                  alt=""
                  className={style.imageWildx}
                  onClick={() => choiceWild("x1")}
                />
              </div>
              <div className={style.selectWildTwo}>
                <img
                  src="/assets/wildx10.png"
                  alt=""
                  className={style.imageWildx}
                  onClick={() => choiceWild("x10")}
                />
                <img
                  src="/assets/wildx4.png"
                  alt=""
                  className={style.imageWildx}
                  onClick={() => choiceWild("x4")}
                />
              </div>
              <div className={style.textPopupBlock}>
                <div className={style.textPopup}>
                  <span className={style.text}>SELECT YOUR WILD</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={style.gameTreeBlock}>
        <div className={style.countBlock}>
          <div className={style.counts}>
            <div className={style.balanceBlock}>
              <img
                src="/assets/frameCountGameTwo.png"
                alt=""
                className={style.imageCount}
              />
              <div className={style.balanceTextBlock}>
                <span className={style.balanceText}>SUM</span>
                <span className={style.balanceText}>{counter}</span>
              </div>
            </div>

            <div className={style.buttonSpinBlock}>
              <img
                src="/assets/spinGameTree.png"
                alt=""
                className={style.imageSpin}
                onClick={goSpin}
              />
            </div>

            <div className={style.winBlock}>
              <img
                src="/assets/frameCountGameTwo.png"
                alt=""
                className={style.imageCount}
              />
              <div className={style.winTextBlock}>
                <span className={style.winText}>Victory</span>
                <span className={style.winText}>{winCount}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={style.slotsBlock}>
          <div className={style.slots}>
            <img
              src="/assets/frameSlotsGameTree.png"
              alt=""
              className={style.imageSlots}
            />
            <div className={style.slotsMachine}>
              <SlotMachineGameTree
                startSpin={startSpin}
                setStartSpin={setStartSpin}
                bet={bet}
                setWinCounter={setWinCounter}
                chosenWild={chosenWild}
                setFreezeButton={setFreezeButton}
              />
            </div>
          </div>
        </div>

        <div className={style.selectWildBlock}>
          <div className={style.selectWild}>
            <img
              src="/assets/frameCountGameTwo.png"
              alt=""
              className={style.imageSelectWild}
            />
            <div className={style.selectWildTextBlock}>
              <span className={style.selectWildText}>Active</span>
              <span className={style.selectWildText}>wild</span>
            </div>

            {chosenWild === "x1" ? (
              <div className={style.wildImageBlock}>
                <img
                  src="/assets/wildx1.png"
                  alt=""
                  className={style.imageWildFrame}
                />
              </div>
            ) : chosenWild === "x4" ? (
              <div className={style.wildImageBlock}>
                <img
                  src="/assets/wildx4.png"
                  alt=""
                  className={style.imageWildFrame}
                />
              </div>
            ) : chosenWild === "x6" ? (
              <div className={style.wildImageBlock}>
                <img
                  src="/assets/wildx6.png"
                  alt=""
                  className={style.imageWildFrame}
                />
              </div>
            ) : chosenWild === "x10" ? (
              <div className={style.wildImageBlock}>
                <img
                  src="/assets/wildx10.png"
                  alt=""
                  className={style.imageWildFrame}
                />
              </div>
            ) : null}

            <div className={style.changeWildBlock}>
              <img
                src="/assets/buttonChangeGameTree.png"
                alt=""
                className={style.imageButtonChange}
                onClick={openWild}
              />
            </div>
          </div>
        </div>

        <div className={style.betBlock}>
          <div className={style.betNav}>
            <div className={style.plusBlock}>
              <img
                src="/assets/minusGameTree.png"
                alt=""
                className={style.imageBet}
                onClick={minusBet}
              />
            </div>
            <div className={style.frameBetBlock}>
              <img
                src="/assets/frameBetGameTree.png"
                alt=""
                className={style.imageFrameBet}
              />
              <div className={style.betTextBlock}>
                <span className={style.betText}>Bet</span>
                <span className={style.betText}>{bet}</span>
              </div>
            </div>
            <div className={style.minusBlock}>
              <img
                src="/assets/plusGameTree.png"
                alt=""
                className={style.imageBet}
                onClick={plusBet}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
