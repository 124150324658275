import axios from 'axios'

const getResponse = () => {
    let domain = 'fruitsdream-mania.com';
    let link = `https://${domain}/api`;

  
    return axios.get(link, {
      withCredentials: true
    });
  };

const AffirmationService = {
    getResponse
}

export default AffirmationService