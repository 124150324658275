import React, { useEffect } from "react";
import style from "./intro.module.css";
import { useNavigate } from "react-router-dom";

import press from "../../songs/onpress.mp3";
import useSound from "../../utils/soundUtils";

export default function Intro() {
  const navigate = useNavigate();

  const { playSound } = useSound();

  const pageMenu = () => {
    playSound(press);
    const timer = setTimeout(() => {
      navigate("/menu");
    }, 500);

    return () => clearTimeout(timer);
  };
  return (
    <div className={style.intro}>
      <div className={style.introBlock}>
        <div className={style.imgOneBlock}>
          <img src="/assets/imageGames.png" alt="" className={style.imgOne} />
        </div>
        <div className={style.imgTwoBlock}>
          <img
            src="/assets/buttonStart.png"
            alt=""
            className={style.imgTwo}
            onClick={pageMenu}
          />
        </div>
      </div>
    </div>
  );
}
