import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import style from "./bonusGame.module.css";
import { useNavigate } from "react-router-dom";

const segments = [
  { label: "x3", id: 0 },
  { label: "x2", id: 1 },
  { label: "x1", id: 2 },
  { label: "x0", id: 3 },
  { label: "x3", id: 4 },
  { label: "x2", id: 5 },
  { label: "x1", id: 6 },
  { label: "x0", id: 7 },
];

const segmentAngle = 360 / segments.length;
const initialOffsetAngle = 0;
const pointerOffsetAngle = 0;

const calculateSegment = (rotation) => {
  let adjustedRotation =
    (rotation + initialOffsetAngle + pointerOffsetAngle) % 360;
  if (adjustedRotation < 0) {
    adjustedRotation += 360;
  }

  const segmentIndex = Math.floor(adjustedRotation / segmentAngle);
  return segments[segmentIndex];
};

export default function AnimatedWheel({
  spinStart,
  setSpinStart,
  setSpinResult,
  page,
  setResCount,
}) {
  const [totalRotation, setTotalRotation] = useState(0);

  const navigate = useNavigate();

  const { rotate } = useSpring({
    rotate: totalRotation,
    config: { duration: 4500, easing: (t) => t * t * t },
    onRest: () => {
      const finalRotation = totalRotation % 360;
      const result = calculateSegment(Math.abs(finalRotation));
      setSpinStart(false);
      setSpinResult(result.label);

      setTimeout(() => {
        navigate(`/${page}`);
        setResCount(null);
      }, 3000);
    },
  });

  const spin = () => {
    let fixedSpins = 3 + Math.random() * 2;
    fixedSpins = Math.round(fixedSpins * 8) / 8;
    const newTotalRotation = totalRotation - 360 * fixedSpins;

    setTotalRotation(newTotalRotation);
  };

  useEffect(() => {
    if (spinStart) {
      spin();
    }
  }, [spinStart]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2%",
      }}
    >
      <animated.div style={{ transform: rotate.to((r) => `rotate(${r}deg)`) }}>
        <img src="/assets/wheel.png" alt="" className={style.imageWeel} />
      </animated.div>
    </div>
  );
}
