import React, { useEffect, useRef, useState } from "react";
import style from "./gameOne.module.css";
import betSVG from "../../svg/bet.svg";
import autoplaySVG from "../../svg/autoplay.svg";
import minusGameOne from "../../svg/minusGameOne.svg";
import plusGameOne from "../../svg/plusGameOne.svg";
import betMinusSVG from "../../svg/betMinus.svg";
import betPlusSVG from "../../svg/betPlus.svg";
import backSVG from "../../svg/back.svg";

import bets from "../../songs/bet.mp3";
import slot from "../../songs/slot.mp3";
import press from "../../songs/onpress.mp3";

import SlotMachine from "./slotMachine";
import { useDispatch, useSelector } from "react-redux";
import { decrement, increment } from "../../store/counter/counter";
import { useLocation, useNavigate } from "react-router-dom";
import useSound from "../../utils/soundUtils";
import { motion } from "framer-motion";

import CryptoJS from "crypto-js";

export default function GameOne() {
  const [startSpin, setStartSpin] = useState(false);
  const counter = useSelector((state) => state.counter.count);
  const [bet, setBet] = useState(50);
  const [winCount, setWinCounter] = useState(0);

  const [openPopupBet, setOpenPopupBet] = useState(false);
  const [openPopupAutoplay, setOpenPopupAutoplay] = useState(false);
  const [gameAutoplay, setGameAutoplay] = useState(false);

  const [bonusGame, setBonusGame] = useState(0);

  const [freezeButton, setFreezeButton] = useState(false);

  const dispatch = useDispatch();

  const { playSound } = useSound();

  const navigate = useNavigate();

  const intervalRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [location]);

  const plusBet = () => {
    playSound(bets);
    setBet((prev) => {
      const newBet =
        prev < counter ? (prev + 50 < counter ? prev + 50 : counter) : counter;
      return newBet;
    });
  };

  const minusBet = () => {
    playSound(bets);

    setBet((prev) => {
      const newBet = prev - 50;

      if (newBet < 0) {
        return 0;
      }

      return newBet;
    });
  };

  const plusBetAutoplay = () => {
    playSound(bets);
    setGameAutoplay(true);

    setBet((prev) => {
      const newBet =
        prev < counter ? (prev + 50 < counter ? prev + 50 : counter) : counter;
      return newBet;
    });
  };

  const minusBetAutoplay = () => {
    playSound(bets);
    setGameAutoplay(true);

    setBet((prev) => {
      const newBet = prev - 50;

      if (newBet < 0) {
        return 0;
      }

      return newBet;
    });
  };

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const generateToken = (page, bet) => {
    const data = { page, bet };
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey
    ).toString();
    return encodeURIComponent(encryptedData);
  };

  useEffect(() => {
    if (bonusGame > 0 && bonusGame % 15 === 0) {
      const page = "gameOne";
      const token = generateToken(page, bet);
      navigate(`/bonus/${token}`);
    }
  }, [bonusGame]);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const goSpin = () => {
    if (bet > 0 && counter >= bet && !freezeButton && !gameAutoplay) {
      setBonusGame(bonusGame + 1);

      clearInterval(intervalRef.current);

      if ((bonusGame + 1) % 15 === 0) {
        setStartSpin(false);
        setFreezeButton(false);
      } else {
        setFreezeButton(true);
        playSound(slot);
        dispatch(decrement(bet));
        setStartSpin(true);
      }
    }

    if (bet > 0 && counter >= bet && gameAutoplay) {
      setBonusGame(bonusGame + 1);
      setGameAutoplay(false);

      if ((bonusGame + 1) % 15 === 0) {
        setStartSpin(false);

        setFreezeButton(false);
      } else {
        playSound(slot);
        dispatch(decrement(bet));
        setStartSpin(true);

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
          if (counter === 0 || bet > counter) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            return;
          }

          playSound(slot);
          dispatch(decrement(bet));
          setStartSpin(true);
        }, 5000);
      }
    } else if (!gameAutoplay && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (intervalRef.current && (counter === 0 || bet > counter)) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [counter, bet]);

  useEffect(() => {
    if (winCount > 0) {
      setTimeout(() => {
        dispatch(increment(winCount));
      }, 1000);
      setTimeout(() => {
        setWinCounter(0);
      }, 1500);
    }
  }, [winCount]);

  const pageWinLineGameOne = () => {
    playSound(press);
    const timer = setTimeout(() => {
      navigate("/winLineGameOne");
    }, 500);

    return () => clearTimeout(timer);
  };

  const onAutoplay = (bet) => {
    playSound(press);
    setBet(bet);
    setGameAutoplay(true);
  };

  const openBetNavAutoplay = () => {
    playSound(press);
    setOpenPopupBet(true);
  };

  const openBetAutoplay = () => {
    playSound(press);
    setOpenPopupAutoplay(true);
  };

  const backPage = () => {
    navigate("/menu");
  };

  return (
    <div className={style.gameOne}>
      <div className={style.blockBack}>
        <img
          src={backSVG}
          alt=""
          onClick={backPage}
          className={style.imageBack}
        />
      </div>
      {openPopupBet && (
        <div
          className={style.popupBetBlock}
          onClick={() => setOpenPopupBet(false)}
        >
          <div className={style.popupBlock}>
            <div className={style.popupBet}>
              <div className={style.betNavFrameBlock}>
                <img
                  src="/assets/frameNavForGameOne.png"
                  alt=""
                  className={style.imagePopup}
                />
                <div className={style.nameFrame}>
                  <span className={style.nameFrameText}>Bet Settings</span>
                </div>

                <div className={style.navForBet}>
                  <img
                    src="/assets/navBetGameOne.png"
                    alt=""
                    className={style.imageNavBet}
                  />
                </div>

                <div className={style.navForBetBlock}>
                  <div>
                    <img
                      src={betPlusSVG}
                      alt=""
                      onClick={minusBetAutoplay}
                      style={{ width: "7vw", height: "auto" }}
                    />
                  </div>

                  <span className={style.nameFrameTextTwoBet}>{bet}</span>

                  <div>
                    <img
                      src={betMinusSVG}
                      alt=""
                      onClick={plusBetAutoplay}
                      style={{ width: "7vw", height: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {openPopupAutoplay && (
        <div
          className={style.popupAutoPlayBlock}
          onClick={() => setOpenPopupAutoplay(false)}
        >
          <div className={style.popupBlock}>
            <div className={style.popupAutoplay}>
              <div className={style.autoPlayNavFrameBlock}>
                <img
                  src="/assets/frameNavForGameOne.png"
                  alt=""
                  className={style.imagePopup}
                />
                <div className={style.nameFrame}>
                  <span className={style.nameFrameText}>Autoplay</span>
                </div>
                <div className={style.autoplayTextBlock}>
                  <span className={style.autoplayText}>
                    Select autoplay count
                  </span>
                </div>

                <div className={style.autoplayBetBlock}>
                  <div className={style.autoplayColOne}>
                    <div>
                      <img
                        src="/assets/bet200.png"
                        alt=""
                        className={style.imageBetAutoplay}
                        onClick={() => onAutoplay(200)}
                      />
                    </div>
                    <div>
                      <img
                        src="/assets/bet250.png"
                        alt=""
                        className={style.imageBetAutoplay}
                        onClick={() => onAutoplay(250)}
                      />
                    </div>
                    <div>
                      <img
                        src="/assets/bet300.png"
                        alt=""
                        className={style.imageBetAutoplay}
                        onClick={() => onAutoplay(300)}
                      />
                    </div>
                  </div>

                  <div className={style.autoplayColTwo}>
                    <div>
                      <img
                        src="/assets/bet50.png"
                        alt=""
                        className={style.imageBetAutoplay}
                        onClick={() => onAutoplay(50)}
                      />
                    </div>
                    <div>
                      <img
                        src="/assets/bet100.png"
                        alt=""
                        className={style.imageBetAutoplay}
                        onClick={() => onAutoplay(100)}
                      />
                    </div>
                    <div>
                      <img
                        src="/assets/bet150.png"
                        alt=""
                        className={style.imageBetAutoplay}
                        onClick={() => onAutoplay(150)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={style.gameOneBlock}>
        <div className={style.navigationBlock}>
          <div className={style.balanceBlock}>
            <div className={style.balance}>
              <div>
                <span className={style.text}>SUM</span>
              </div>
              <div>
                <span className={style.text}>{counter}</span>
              </div>
            </div>
          </div>

          <div className={style.navigateBlock}>
            <div className={style.imageBetSVGBlock}>
              <img
                src={betSVG}
                alt=""
                className={style.imageSVG}
                onClick={() => openBetNavAutoplay()}
              />
            </div>
            <div className={style.spin}>
              <img
                src="/assets/buttonStart.png"
                alt=""
                className={style.imageSpin}
                onClick={goSpin}
              />
            </div>
            <div className={style.imageAutoplaySVGBlock}>
              <img
                src={autoplaySVG}
                alt=""
                className={style.imageSVG}
                onClick={() => openBetAutoplay()}
              />
            </div>
          </div>

          <div className={style.betBlock}>
            <div className={style.bet}>
              <div>
                <span className={style.text}>Victory</span>
              </div>
              <div>
                <span className={style.text}>{winCount}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={style.blockSlots}>
          <div className={style.emptyBlock}></div>
          <div className={style.slots}>
            <img
              src="/assets/frameForGameOne.png"
              alt=""
              className={style.imageFrameForSlots}
            />
            <div className={style.slotsMachine}>
              <SlotMachine
                startSpin={startSpin}
                setStartSpin={setStartSpin}
                bet={bet}
                setWinCounter={setWinCounter}
                setFreezeButton={setFreezeButton}
              />
            </div>
            {winCount > 0 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className={style.backWinBlock}
              >
                <div className={style.backWin}>
                  <div>
                    <img
                      src="/assets/lightWin.png"
                      alt=""
                      className={style.imageLightWin}
                    />
                  </div>

                  <div className={style.blockResWin}>
                    <div className={style.winResTextBlock}>
                      <span className={style.winResText}>{winCount}</span>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </div>
          <div className={style.betBlockNav}>
            <div className={style.betNav}>
              <div className={style.betNavigate}>
                <div className={style.buttonBetNav}>
                  <img
                    src={minusGameOne}
                    alt=""
                    onClick={minusBet}
                    style={{ width: "10vw", height: "auto" }}
                  />
                </div>
                <div className={style.betNavBack}>
                  <div className={style.betBlockText}>
                    <div>
                      <span className={style.betText}>{bet}</span>
                    </div>
                  </div>
                </div>
                <div className={style.buttonBetNav}>
                  <img
                    src={plusGameOne}
                    alt=""
                    onClick={plusBet}
                    style={{ width: "10vw", height: "auto" }}
                  />
                </div>
              </div>

              <div className={style.winLine}>
                <div className={style.winLineBlock}>
                  <span
                    className={style.winLineText}
                    onClick={pageWinLineGameOne}
                  >
                    winning lines
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
