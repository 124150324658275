import React from "react";
import style from "./policy.module.css";

export default function Policy() {
  const openGooglePrivacy = () => {
    window.open("https://www.google.com/policies/privacy/", "_blank");
  };

  return (
    <div className={style.privacyPolicy}>
      <h1 className={style.title}>Privacy Policy</h1>
      <p>
        Welcome to the Fruits Dream Mania app ("App"), provided to you as a free
        service. This Privacy Policy outlines how we process your information
        while using the App, which is provided "AS IS" without any warranties or
        obligations. Our goal is to ensure your privacy and create a safe and
        enjoyable user experience.
      </p>

      <section className={style.section}>
        <h2 className={style.subtitle}>Information Collection and Use</h2>
        <p>
          The App collects information during your download and usage. This
          information may include:
        </p>
        <ul>
          <li>Your device's IP address</li>
          <li>
            Pages you visit within the App, the time and date of visits, and the
            duration spent on the pages
          </li>
          <li>The operating system of your mobile device</li>
          <li>Additional usage data</li>
        </ul>
        <p>
          The information you provide may be used to periodically contact you
          with important updates, notifications, and marketing offers.
        </p>
        <p>
          Our app uses libraries that may access your approximate location to
          improve functionality and tailor advertising to your interests. This
          data is used with respect to privacy, and precise location information
          is not collected. You can change location permissions in your device
          settings at any time.
        </p>
      </section>

      <section className={style.section}>
        <h2 className={style.subtitle}>Opt-Out Rights</h2>
        <p>
          You can stop all information collection by the App by uninstalling it.
          You can do this using the standard uninstall processes on your mobile
          device or through the app marketplace.
        </p>
      </section>

      <section className={style.section}>
        <h2 className={style.subtitle}>Data Retention Policy</h2>
        <p>
          We retain user-provided data for as long as you use the App and for a
          reasonable period afterward. If you wish to have your data deleted,
          please send a request to{" "}
          <a href="mailto:teem.support.power@proton.me">
            teem.support.power@proton.me
          </a>
          , and we will respond as soon as possible.
        </p>
      </section>

      <section className={style.section}>
        <h2 className={style.subtitle}>Third-Party Access</h2>
        <p>
          We may periodically share anonymized data with external services to
          improve the App. Your information may be shared with third parties in
          accordance with this Privacy Policy.
        </p>
        <p>
          Please note that our App uses third-party services, each with its own
          privacy policies. Below are links to the privacy policies of the
          third-party services used by the App:
        </p>
        <ul>
          <li style={{ color: "blue" }} onClick={openGooglePrivacy}>
            Google Play Services
          </li>
        </ul>
        <p>
          We may disclose user-provided and automatically collected information
          in the following cases:
        </p>
        <ul>
          <li>To comply with the law or legal process;</li>
          <li>To protect rights, ensure safety, or investigate fraud;</li>
          <li>
            To third parties working on our behalf, provided they adhere to this
            Privacy Policy.
          </li>
        </ul>
      </section>

      <section className={style.section}>
        <h2 className={style.subtitle}>Children</h2>
        <p>
          We do not knowingly collect data from or target children under the age
          of 13. If we discover that a child under 13 has provided personal
          information, it will be deleted immediately. If you are a parent or
          guardian and believe your child has provided personal information,
          please contact us so we can take appropriate action.
        </p>
      </section>

      <section className={style.section}>
        <h2 className={style.subtitle}>Security</h2>
        <p>
          We are committed to protecting the confidentiality of your
          information. We implement physical, electronic, and procedural
          safeguards to secure the information we process and store.
        </p>
      </section>

      <section className={style.section}>
        <h2 className={style.subtitle}>Changes</h2>
        <p>
          This Privacy Policy may be updated periodically. We will notify you of
          any changes by updating this page. We recommend reviewing this Privacy
          Policy regularly to stay informed of any updates.
        </p>
        <p>
          <strong>
            This Privacy Policy is effective as of September 6, 2024.
          </strong>
        </p>
      </section>

      <section className={style.section}>
        <h2 className={style.subtitle}>Your Consent</h2>
        <p>
          By using the App, you consent to the processing of your information as
          outlined in this Privacy Policy.
        </p>
      </section>

      <section className={style.section}>
        <h2 className={style.subtitle}>Contact Us</h2>
        <p>
          If you have any questions regarding privacy while using the App or
          about our practices, please contact us at:
        </p>
        <p>
          <a href="mailto:teem.support.power@proton.me">
            teem.support.power@proton.me
          </a>
        </p>
      </section>
    </div>
  );
}
